import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild
} from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import Panzoom, { PanzoomObject } from '@panzoom/panzoom';
import { Helper } from 'app/common/helper';
import {
  Constant,
  DestinationEnum,
  DisplayCanvasIdEnum,
  DisplaysEnum,
  ErrorEnum,
  FIELD_COMPONENT,
  FolderNameDropPDFEnum,
  LinkDataPictureEnum,
  LinkDataTextEnum,
  MODULE_NAME,
  PreviewToolEnum,
  ReferencePositionTimetableColumnEnum,
  ScreenCanvasIdEnum,
  TypeMediaFileEnum
} from 'app/config/constants';
import { DialogConfirmComponent } from 'app/dialog/dialog-confirm/dialog-confirm.component';
import { DeviceInformation } from 'app/dialog/dialog-delivery-timetable/dialog-delivery-timetable.component';
import { DialogEmergencyModeComponent } from 'app/dialog/dialog-emergency-mode/dialog-emergency-mode.component';
import { DialogMessageComponent } from 'app/dialog/dialog-message/dialog-message.component';
import { DialogOperationInformationSettingComponent } from 'app/dialog/dialog-operation-information-setting/dialog-operation-information-setting.component';
import { Area } from 'app/model/entity/area';
import { Common } from 'app/model/entity/common';
import { DataExternalSetting } from 'app/model/entity/data-external-setting';
import { Device } from 'app/model/entity/device';
import { DisplayTemplate } from 'app/model/entity/display-template';
import { DynamicMessage } from 'app/model/entity/dynamic-message';
import { EmergencyData } from 'app/model/entity/emergency-data';
import { Image as ImageDynamicMessage } from 'app/model/entity/image';
import { Media } from 'app/model/entity/media';
import { OperationInformationSetting } from 'app/model/entity/operation-information-setting';
import { PictureArea } from 'app/model/entity/picture-area';
import { ScheduleDisplayIndex } from 'app/model/entity/schedule-display-index';
import { ScheduleOperationInfo } from 'app/model/entity/schedule-operation-info';
import { Template } from 'app/model/entity/template';
import { TextArea } from 'app/model/entity/text-area';
import { Timetable } from 'app/model/entity/timetable';
import { SaveMainStateAction, SaveTimetableOperationManagerStateAction } from 'app/ngrx-component-state-management/component-state.action';
import { APICustomerService } from 'app/service/api-customer.service';
import { CommonService } from 'app/service/common.service';
import { DataService } from 'app/service/data.service';
import { DeliveryStatusTimetableService } from 'app/service/delivery-status-timetable.service';
import { DeviceService } from 'app/service/device.service';
import { DialogService } from 'app/service/dialog.service';
import { DrawTimetableService } from 'app/service/draw-timetable.service';
import { DrawService } from 'app/service/draw.service';
import { DynamicMessageService } from 'app/service/dynamic-message.service';
import { EmergencyDataService } from 'app/service/emergency-data.service';
import { ExecutingService } from 'app/service/executing.service';
import { IndexWordService } from 'app/service/index-word.service';
import { MediaService } from 'app/service/media.service';
import { MenuActionService } from 'app/service/menu-action.service';
import { OperationInformationSettingService } from 'app/service/operation-info-setting.service';
import { PictureAreaService } from 'app/service/picture-area-service';
import { ScheduleDisplayIndexService } from 'app/service/schedule-display-index.service';
import { ScheduleOperationInfoService } from 'app/service/schedule-operation-info.service';
import { TimetableContentDayService } from 'app/service/timetable-content-day.service';
import { TimetableService } from 'app/service/timetable.service';
import { AppState } from 'app/store/app.state';
import _ from 'lodash';
import { DateTime } from 'luxon';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, interval, Subject, Subscription, timer } from 'rxjs';
import { concatMap, delay, startWith, switchMap, take, takeUntil } from 'rxjs/operators';
import { v4 as uniqueId } from 'uuid';
import { MediaFileDropped } from '../lcd-layout-editor/lcd-layout-editor.component';
@Component({
  selector: 'timetable-operation-manager',
  templateUrl: './timetable-operation-manager.component.html',
  styleUrls: ['./timetable-operation-manager.component.scss']
})
export class TimetableOperationManagerComponent implements OnInit, OnDestroy {
  //#region public global var
  public readonly PATH_ANGLE_DOUBLE_RIGHT = Constant.PATH_ANGLE_DOUBLE_RIGHT;
  public readonly PreviewToolEnum = PreviewToolEnum;
  public readonly DisplaysEnum = DisplaysEnum;
  public Helper = Helper;
  public operationInfoOptions: Array<OperationInformationSetting>;
  public readonly canvasDisplay1Id = `${ScreenCanvasIdEnum.TIMETABLE_OPERATION_MANAGER}${DisplayCanvasIdEnum.DISPLAY_1}`;
  public readonly canvasDisplay2Id = `${ScreenCanvasIdEnum.TIMETABLE_OPERATION_MANAGER}${DisplayCanvasIdEnum.DISPLAY_2}`;
  public readonly tableBodyId = 'timetable-operation-table-grid-body';
  public isPlayPreview: boolean = false;
  public isEnlargePreview: boolean = false;
  public isEditDynamicMessageMode: boolean = false;
  public isEditEmergencyMode: boolean = false;
  public isViewMonitorMode: boolean = false;
  public isZoom: boolean = false;
  public isPan: boolean = false;
  public deviceSelected: Device;
  public devices: Device[];
  public schedules: Schedule[];
  public scheduleColumns: Array<string>;
  public activeScheduleRow: ActiveScheduleRow;
  public timetableSelected: Timetable;
  public dynamicMessages: DynamicMessage[];
  public dynamicMessageSelected: DynamicMessage;
  public oldDynamicMessages: DynamicMessage[];
  public emergencyData: EmergencyData = new EmergencyData(Constant.EMPTY, -1);
  public isDisplay2: boolean;
  public buttonsPreviewDisplay1: Array<{ key: DestinationEnum; value: string }> = [];
  public buttonsPreviewDisplay2: Array<{ key: DestinationEnum; value: string }> = [];
  public templateSelectedTypeDisplay1: DestinationEnum = DestinationEnum.MAIN;
  public templateSelectedTypeDisplay2: DestinationEnum = DestinationEnum.MAIN;
  public oldOperationInfos: OperationInformationSetting[];
  //#endregion

  //#region private global var
  private readonly SCREEN_ID = 'timetable-operation';
  private readonly DEFAULT_TIMEOUT = 2;
  private readonly MAX_CURRENT_INDEX = 100;
  private readonly updateStateItemOnMenu = new Subject();
  private readonly resetTimerCheckLessCurrentTimeSubject = new Subject();
  private readonly pauseTimerCheckLessCurrentTimeSubject = new Subject();
  private readonly startPreviewSubject = new Subject();
  private readonly pausePreviewSubject = new Subject();
  private readonly resetTimerGetDataEveryDaySubject = new Subject();
  private readonly pauseTimerGetDataEveryDaySubject = new Subject();
  private readonly toSwitchBetweenPageSubject = new Subject();
  private readonly ENTER_KEY_CODE = 13;
  private readonly MAX_LENGTH_VALUE = 256;
  private readonly propertiesName = {
    dynamicMessage: this.translateService.instant('dialog-message.timetable-operation.dynamic-message'),
    emergencyLinkText: this.translateService.instant('dialog-message.timetable-operation.link-text')
  };
  private readonly TIME_RESET = 14400;
  private readonly NUMBERS_OF_API_CALLS = 10;
  private readonly NUMBER_MILLISECONDS_CALL_API = 1000;
  private readonly FIRST_ELEMENT_INDEX = 0;
  private readonly ROW_NAME = 'rowName';
  private readonly INDEX_DRAG = 'indexDrag';
  private readonly FORMAT_HOURS = 'HH:mm:ss';
  private readonly CURSOR_MOVE = 'move';
  private readonly CURSOR_POINTER = 'pointer';
  private readonly CURSOR = 'cursor';
  private readonly CURSOR_DEFAULT = 'default';
  private readonly TIMETABLE_MODE_ERROR_CODE = 'change-timetable-failed-message';
  private readonly CURRENT_INDEX_NUMBER = 'currentIndexNumber';
  private readonly DELAY_INFORMATION = 'delayInformation';
  private readonly DYNAMIC_MESSAGE = 'dynamicMessage';
  private readonly EMERGENCY_MESSAGE = 'emergencyMessage';
  private readonly IMAGE_ATTRIBUTE = 'image';
  private readonly MESSAGE_ATTRIBUTE = 'message';
  private readonly AUTOMATION_MODE = 'automationMode';

  private readonly STYLE = 'style';
  readonly TIMEOUT_SEC = 5;
  private readonly CURRENT = 'current';
  private readonly INDEX_CURRENT = 'index_current';
  private readonly IMAGE_NAME_DROP_MEDIA = 'imageDynamic';
  private readonly AREA_ID = 'areaId';
  private readonly URL_MEDIA_DROP_PC = 'urlMediaDropPC';
  private subscriptions: Array<Subscription> = new Array<Subscription>();
  private panzoomDisplay1: PanzoomObject;
  private panzoomDisplay2: PanzoomObject;
  private linkAreasDisplay1: Area[];
  private linkAreasDisplay2: Area[];
  private timeoutsDisplay1: any[] = [];
  private timeoutsDisplay2: any[] = [];
  private currentIndexTimetableSchedule: number = 0;
  private scheduleOperationInfo: ScheduleOperationInfo;
  private scheduleDisplayIndex: ScheduleDisplayIndex;
  private monitorResponseData: any;
  private monitorResponseDataOld: any;
  private scheduleRowIndex: any;
  private oldScheduleRowIndex: any;
  private deviceIdInCommonObject: number;
  private rowName = [];
  private timetablePositionRowDisplay1Set: Set<any>;
  private timetablePositionRowDisplay2Set: Set<any>;
  private areaSwitchingTiming: number = 0;
  public lastTimeUpdate;
  private timeByImg;
  /**
   * Emergency media id old
   */
  private emergencyMediaIdOld: Number;
  /**
   * subscribe for get data external content to preview
   */
  private subscribesGetDataExternal: Subscription[] = [];
  /**
   * save data success
   */
  @Output() saveDataSuccess = new EventEmitter<boolean>();
  /**
   * draw index words success
   */
  @Output() drawIndexWordsSuccess = new EventEmitter<boolean>();
  /**
   * offset value to delay the change over timing of timetable.
   */
  public changeoverOffset: number;
  /**
   * true if network OK
   */
  public isNetworkOK: boolean;
  /**
   * Subject
   */
  private subject = new Subject();
  /**
   * time date line
   */
  timeDateLine: number;
  /**
   * timetable of mode preview
   */
  timetableModePreview: Timetable;
  /**
   * type display 1 of mode preview
   */
  typeDisplay1ModePreview: DestinationEnum;
  /**
   * type display 2 of mode preview
   */
  typeDisplay2ModePreview: DestinationEnum;
  /**
   * list file data
   */
  private filesData: any[];
  /**
   * media files dropped operation
   */
  private mediaFilesDroppedOperation: Array<MediaFileDropped> = new Array();
  /**
   * true if dynamic message only link text
   */
  public isOnlyTextArea: boolean;
  /**
   * true if dynamic message only link picture
   */
  public isOnlyPictureArea: boolean;
  /**
   * media's id to delete
   */
  private mediaIdsToDelete: Array<Number> = new Array();
  /**
   * templateOld
   */
  templateOld: Template;
  /**
   * language key
   */
  languageKey: string;
  /**
   * state of component
   */
  private stateOfComponent: {
    isChangeLayout: boolean;
    isEnlargePreview: boolean;
    isEditDynamicMessageMode: boolean;
    isEditEmergencyMode: boolean;
    isViewMonitorMode: boolean;
    isZoom: boolean;
    isPan: boolean;
    deviceSelected: Device;
    devices: Device[];
    schedules: Schedule[];
    scheduleColumns: Array<string>;
    activeScheduleRow: ActiveScheduleRow;
    timetableSelected: Timetable;
    dynamicMessages: DynamicMessage[];
    oldDynamicMessages: DynamicMessage[];
    emergencyData: EmergencyData;
    buttonsPreviewDisplay1: Array<{ key: DestinationEnum; value: string }>;
    buttonsPreviewDisplay2: Array<{ key: DestinationEnum; value: string }>;
    templateSelectedTypeDisplay1: DestinationEnum;
    templateSelectedTypeDisplay2: DestinationEnum;
    linkAreasDisplay1: Area[];
    linkAreasDisplay2: Area[];
    currentIndexTimetableSchedule: number;
    scheduleOperationInfo: ScheduleOperationInfo;
    scheduleRowIndex: any;
    timetablePositionRowDisplay1Set: Set<any>;
    timetablePositionRowDisplay2Set: Set<any>;
    isPlayPreview: boolean;
    operationInfoOptions: Array<OperationInformationSetting>;
    changeoverOffset: number;
    oldOperationInfos: OperationInformationSetting[];
    oldScheduleRowIndex: any;
    scheduleDisplayIndex: any;
    timeDateLine: number;
    timetableModePreview: Timetable;
    typeDisplay1ModePreview: DestinationEnum;
    typeDisplay2ModePreview: DestinationEnum;
    monitorResponseData: any;
    isNetworkOK: boolean;
    emergencyMediaIdOld: Number;
    areaSwitchingTiming: number;
    filesData: any;
    isOnlyTextArea: boolean;
    isOnlyPictureArea: boolean;
    mediaFilesDroppedOperation: Array<MediaFileDropped>;
    mediaIdsToDelete: Array<Number>;
  };
  //#endregion

  //#region view child element
  @ViewChild('divContainCanvas1', { static: false })
  divContainCanvas1: ElementRef;

  @ViewChild('divContainCanvas2', { static: false })
  divContainCanvas2: ElementRef;

  @ViewChild('divPreviewDisplay1', { static: false })
  divPreviewDisplay1: ElementRef;

  @ViewChild('divPreviewDisplay2', { static: false })
  divPreviewDisplay2: ElementRef;
  //#endregion

  /**
   * common object
   */
  commonObject: Common;
  constructor(
    private actionService: MenuActionService,
    private deviceService: DeviceService,
    private dialogService: DialogService,
    private renderer: Renderer2,
    private timetableService: TimetableService,
    private dynamicMessageService: DynamicMessageService,
    private emergencyDataService: EmergencyDataService,
    private apiCustomerService: APICustomerService,
    private scheduleOperationInfoService: ScheduleOperationInfoService,
    private dataService: DataService,
    private drawService: DrawService,
    private drawTimetableService: DrawTimetableService,
    private mediaService: MediaService,
    private pictureAreaService: PictureAreaService,
    private indexWordService: IndexWordService,
    private changeDetectorRef: ChangeDetectorRef,
    private readonly store: Store<AppState>,
    private translateService: TranslateService,
    private toast: ToastrService,
    private commonService: CommonService,
    private operationInfoService: OperationInformationSettingService,
    private executingService: ExecutingService,
    private scheduleDisplayIndexService: ScheduleDisplayIndexService,
    private timetableContentDayService: TimetableContentDayService,
    private deliveryStatusTimetableStatus: DeliveryStatusTimetableService
  ) {
    this.subscriptions.push(
      this.actionService.actionOperationSetting.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.TimetableOperationManagerComponent]) {
          this.operationInformationSetting();
        }
      })
    );
    this.subscriptions.push(
      this.actionService.actionSave.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.TimetableOperationManagerComponent]) {
          const checkedDevices = this.devices.filter(device => device.isChecked);
          this.saveDynamicMessages(this.dynamicMessages, checkedDevices);
        }
      })
    );
    this.subscriptions.push(
      this.actionService.actionDynamicMessage.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.TimetableOperationManagerComponent]) {
          this.openDynamicMessage();
        }
      })
    );
    this.subscriptions.push(
      this.actionService.actionEmergency.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.TimetableOperationManagerComponent]) {
          this.openEmergency();
        }
      })
    );
    this.subscriptions.push(
      this.actionService.actionChangeDisplay.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.TimetableOperationManagerComponent]) {
          this.changeDisplay();
        }
      })
    );
    // subscribe for clear field data
    this.subscriptions.push(
      this.actionService.actionClearField.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.TimetableOperationManagerComponent]) {
          this.clearField();
        }
      })
    );
    // on-click go to
    this.subscriptions.push(
      this.toSwitchBetweenPageSubject.subscribe((destinationDisplay: { key: string; value: DestinationEnum }) => {
        if (!this.isPlayPreview) {
          return;
        }
        if (destinationDisplay.key == this.canvasDisplay1Id && this.timetableSelected?.templateDisplay1s) {
          if (this.timetableSelected.templateDisplay1s[this.templateSelectedTypeDisplay1]?.isAutoTransition) {
            this.clearTimeoutDisplay(this.timeoutsDisplay1);
          }
          this.handleEventClickAreaOnCanvas(
            this.divContainCanvas1,
            this.canvasDisplay1Id,
            this.timetableSelected.templateDisplay1s,
            destinationDisplay.value
          );
        } else if (destinationDisplay.key == this.canvasDisplay2Id && this.timetableSelected?.templateDisplay2s) {
          if (this.timetableSelected.templateDisplay2s[this.templateSelectedTypeDisplay2]?.isAutoTransition) {
            this.clearTimeoutDisplay(this.timeoutsDisplay2);
          }
          this.handleEventClickAreaOnCanvas(
            this.divContainCanvas2,
            this.canvasDisplay2Id,
            this.timetableSelected.templateDisplay2s,
            destinationDisplay.value
          );
        }
      })
    );
    this.subscriptions.push(
      this.updateStateItemOnMenu.subscribe(() => {
        this.dataService.sendData([
          `${MODULE_NAME[FIELD_COMPONENT.TimetableOperationManagerComponent]}:isEditDynamicMessageMode`,
          this.isEditDynamicMessageMode
        ]);
        this.dataService.sendData([
          `${MODULE_NAME[FIELD_COMPONENT.TimetableOperationManagerComponent]}:isEditEmergencyMode`,
          this.isEditEmergencyMode
        ]);
        this.dataService.sendData([`${MODULE_NAME[FIELD_COMPONENT.TimetableOperationManagerComponent]}:isPlayPreview`, this.isPlayPreview]);
        this.dataService.sendData([
          `${MODULE_NAME[FIELD_COMPONENT.TimetableOperationManagerComponent]}:isViewMonitorMode`,
          this.isViewMonitorMode
        ]);
      })
    );
    this.subscriptions.push(
      this.translateService.onLangChange.subscribe(() => {
        this.languageKey = this.commonService.getCommonObject().setting?.language;
        this.lastTimeUpdate = Helper.formatString(
          this.translateService.instant(`timetable-operation-manager.last-time-update`),
          Helper.updateLanguageLastTime(this.timeByImg, this.languageKey)
        );
        if (this.buttonsPreviewDisplay1) {
          this.buttonsPreviewDisplay1 = this.getButtonsPreview(this.timetableSelected?.displayTemplate1);
        }
        if (this.buttonsPreviewDisplay2) {
          this.buttonsPreviewDisplay2 = this.getButtonsPreview(this.timetableSelected?.displayTemplate2);
        }
        if (this.timetableSelected?.timetableSchedule?.headers?.length > 0) {
          Helper.updateLanguageHeaders(this.timetableSelected.timetableSchedule.headers, this.translateService);
          this.scheduleColumns = this.timetableSelected.timetableSchedule.headers.filter(header => header != null);
        }
        this.rowName[0].name = this.translateService.instant('timetable-operation-manager.first');
        if (this.activeScheduleRow?.current_0) {
          this.activeScheduleRow.current_0.rowName = this.translateService.instant('timetable-operation-manager.first');
        }
      })
    );
    this.subscriptions.push(
      this.drawIndexWordsSuccess.subscribe(() => {
        this.drawTimetableService.playPreview(this.canvasDisplay1Id, ScreenCanvasIdEnum.TIMETABLE_OPERATION_MANAGER);
        if (this.isDisplay2) {
          this.drawTimetableService.playPreview(this.canvasDisplay2Id, ScreenCanvasIdEnum.TIMETABLE_OPERATION_MANAGER);
        }
      })
    );
    this.subscriptions.push(
      this.store
        .select(state => state)
        .subscribe((contentState: any) => {
          this.stateOfComponent = {
            isChangeLayout: contentState?.timetableOperationManagerState?.stateOfComponent.isChangeLayout,
            isEnlargePreview: contentState?.timetableOperationManagerState?.stateOfComponent.isEnlargePreview,
            isEditDynamicMessageMode: contentState?.timetableOperationManagerState?.stateOfComponent.isEditDynamicMessageMode,
            isEditEmergencyMode: contentState?.timetableOperationManagerState?.stateOfComponent.isEditEmergencyMode,
            isViewMonitorMode: contentState?.timetableOperationManagerState?.stateOfComponent.isViewMonitorMode,
            isZoom: contentState?.timetableOperationManagerState?.stateOfComponent.isZoom,
            isPan: contentState?.timetableOperationManagerState?.stateOfComponent.isPan,
            deviceSelected: contentState?.timetableOperationManagerState?.stateOfComponent.deviceSelected,
            devices: contentState?.timetableOperationManagerState?.stateOfComponent.devices,
            schedules: contentState?.timetableOperationManagerState?.stateOfComponent.schedules,
            scheduleColumns: contentState?.timetableOperationManagerState?.stateOfComponent.scheduleColumns,
            activeScheduleRow: contentState?.timetableOperationManagerState?.stateOfComponent.activeScheduleRow,
            timetableSelected: contentState?.timetableOperationManagerState?.stateOfComponent.timetableSelected,
            dynamicMessages: contentState?.timetableOperationManagerState?.stateOfComponent.dynamicMessages,
            emergencyData: contentState?.timetableOperationManagerState?.stateOfComponent.emergencyData,
            buttonsPreviewDisplay1: contentState?.timetableOperationManagerState?.stateOfComponent.buttonsPreviewDisplay1,
            buttonsPreviewDisplay2: contentState?.timetableOperationManagerState?.stateOfComponent.buttonsPreviewDisplay2,
            templateSelectedTypeDisplay1: contentState?.timetableOperationManagerState?.stateOfComponent.templateSelectedTypeDisplay1,
            templateSelectedTypeDisplay2: contentState?.timetableOperationManagerState?.stateOfComponent.templateSelectedTypeDisplay2,
            linkAreasDisplay1: contentState?.timetableOperationManagerState?.stateOfComponent.linkAreasDisplay1,
            linkAreasDisplay2: contentState?.timetableOperationManagerState?.stateOfComponent.linkAreasDisplay2,
            currentIndexTimetableSchedule: contentState?.timetableOperationManagerState?.stateOfComponent.currentIndexTimetableSchedule,
            scheduleOperationInfo: contentState?.timetableOperationManagerState?.stateOfComponent.scheduleOperationInfo,
            scheduleRowIndex: contentState?.timetableOperationManagerState?.stateOfComponent.scheduleRowIndex,
            timetablePositionRowDisplay1Set: contentState?.timetableOperationManagerState?.stateOfComponent.timetablePositionRowDisplay1Set,
            timetablePositionRowDisplay2Set: contentState?.timetableOperationManagerState?.stateOfComponent.timetablePositionRowDisplay2Set,
            isPlayPreview: contentState?.timetableOperationManagerState?.stateOfComponent.isPlayPreview,
            operationInfoOptions: contentState?.timetableOperationManagerState?.stateOfComponent.operationInfoOptions,
            changeoverOffset: contentState?.timetableOperationManagerState?.stateOfComponent.changeoverOffset,
            oldDynamicMessages: contentState?.timetableOperationManagerState?.stateOfComponent.oldDynamicMessages,
            oldOperationInfos: contentState?.timetableOperationManagerState?.stateOfComponent.oldOperationInfos,
            oldScheduleRowIndex: contentState?.timetableOperationManagerState?.stateOfComponent.oldScheduleRowIndex,
            scheduleDisplayIndex: contentState?.timetableOperationManagerState?.stateOfComponent.scheduleDisplayIndex,
            timeDateLine: contentState?.timetableOperationManagerState?.stateOfComponent.timeDateLine,
            timetableModePreview: contentState?.timetableOperationManagerState?.stateOfComponent.timetableModePreview,
            typeDisplay1ModePreview: contentState?.timetableOperationManagerState?.stateOfComponent.typeDisplay1ModePreview,
            typeDisplay2ModePreview: contentState?.timetableOperationManagerState?.stateOfComponent.typeDisplay2ModePreview,
            monitorResponseData: contentState?.timetableOperationManagerState?.stateOfComponent.monitorResponseData,
            isNetworkOK: contentState?.timetableOperationManagerState?.stateOfComponent.isNetworkOK,
            emergencyMediaIdOld: contentState?.timetableOperationManagerState?.stateOfComponent.emergencyMediaIdOld,
            areaSwitchingTiming: contentState?.timetableOperationManagerState?.stateOfComponent.areaSwitchingTiming,
            filesData: contentState?.timetableOperationManagerState?.stateOfComponent.filesData,
            isOnlyTextArea: contentState?.timetableOperationManagerState?.stateOfComponent.isOnlyTextArea,
            isOnlyPictureArea: contentState?.timetableOperationManagerState?.stateOfComponent.isOnlyPictureArea,
            mediaFilesDroppedOperation: contentState?.timetableOperationManagerState?.stateOfComponent.mediaFilesDroppedOperation,
            mediaIdsToDelete: contentState?.timetableOperationManagerState?.stateOfComponent.mediaIdsToDelete
          };
        })
    );

    this.commonObject = commonService.getCommonObject();
  }

  async ngOnInit() {
    this.languageKey = this.commonObject?.setting?.language;
    this.isDisplay2 = this.commonObject.isDisplay2TimetableOperation;
    this.sendTimezoneToBack();
    this.getRowName();
    if (!this.stateOfComponent?.isChangeLayout) {
      this.getAllOperationInfos();
      this.executingService.executing();
      await Helper.loadFontsToPreview(this.store, this.commonObject, this.translateService, this.dialogService);
      this.executingService.executed();
      this.getAllDevices();
    } else {
      this.handleSetDataForComponentAfterChangeLayout();
      this.sendTimezoneToBack();
    }
    this.dataService.currentData.subscribe(data => {
      if (data[0] == Constant.IS_CHANGE_TIME_ZONE) {
        if (data[1]) {
          this.pauseTimerGetDataEveryDaySubject.next();
          this.clearAllDrawThread();
          this.getDataDeviceEveryDay();
          let index = this.devices.findIndex(device => device.id == this.deviceSelected.id);
          this.selectDevice(this.deviceSelected, index);
          this.sendTimezoneToBack();
        }
      }
    });
  }

  /**
   * get all devices
   */
  private getAllDevices(): void {
    this.deviceService.getDevice().subscribe(
      devicesData => {
        this.devices = devicesData;
        this.deviceIdInCommonObject = this.commonObject.timetableOperationDeviceId;
        let oldDeviceSelectedIndex = this.devices.findIndex(device => device.id == this.deviceIdInCommonObject);
        if (oldDeviceSelectedIndex != -1) {
          this.selectDevice(this.devices[oldDeviceSelectedIndex], oldDeviceSelectedIndex);
        } else {
          this.selectDevice(this.devices[this.FIRST_ELEMENT_INDEX], this.FIRST_ELEMENT_INDEX);
        }
      },
      () => this.handleErrorFromApi()
    );
  }

  /**
   * get all operation infos
   */
  private getAllOperationInfos(): void {
    this.operationInfoService.getOperationInfos().subscribe(
      data => {
        this.operationInfoOptions = data;
        this.oldOperationInfos = _.cloneDeep(this.operationInfoOptions);
      },
      () => this.handleErrorFromApi()
    );
  }

  /**
   * set data for component after change layout
   */
  private handleSetDataForComponentAfterChangeLayout() {
    this.isEnlargePreview = this.stateOfComponent.isEnlargePreview;
    this.isEditDynamicMessageMode = this.stateOfComponent.isEditDynamicMessageMode;
    this.isEditEmergencyMode = this.stateOfComponent.isEditEmergencyMode;
    this.isViewMonitorMode = this.stateOfComponent.isViewMonitorMode;
    this.isZoom = this.stateOfComponent.isZoom;
    this.isPan = this.stateOfComponent.isPan;
    this.deviceSelected = this.stateOfComponent.deviceSelected;
    this.devices = this.stateOfComponent.devices;
    this.schedules = this.stateOfComponent.schedules;
    this.scheduleColumns = this.stateOfComponent.scheduleColumns;
    this.activeScheduleRow = this.stateOfComponent.activeScheduleRow;
    this.timetableSelected = this.stateOfComponent.timetableSelected;
    this.dynamicMessages = this.stateOfComponent.dynamicMessages;
    this.emergencyData = this.stateOfComponent.emergencyData;
    this.buttonsPreviewDisplay1 = this.stateOfComponent.buttonsPreviewDisplay1;
    this.buttonsPreviewDisplay2 = this.stateOfComponent.buttonsPreviewDisplay2;
    this.templateSelectedTypeDisplay1 = this.stateOfComponent.templateSelectedTypeDisplay1;
    this.templateSelectedTypeDisplay2 = this.stateOfComponent.templateSelectedTypeDisplay2;
    this.linkAreasDisplay1 = this.stateOfComponent.linkAreasDisplay1;
    this.linkAreasDisplay2 = this.stateOfComponent.linkAreasDisplay2;
    this.currentIndexTimetableSchedule = this.stateOfComponent.currentIndexTimetableSchedule;
    this.scheduleOperationInfo = this.stateOfComponent.scheduleOperationInfo;
    this.scheduleRowIndex = this.stateOfComponent.scheduleRowIndex;
    this.timetablePositionRowDisplay1Set = this.stateOfComponent.timetablePositionRowDisplay1Set;
    this.timetablePositionRowDisplay2Set = this.stateOfComponent.timetablePositionRowDisplay2Set;
    this.updateStateItemOnMenu.next();
    this.isPlayPreview = this.stateOfComponent.isPlayPreview;
    this.isDisplay2 = this.commonObject.isDisplay2TimetableOperation;
    this.operationInfoOptions = this.stateOfComponent.operationInfoOptions;
    this.changeoverOffset = this.stateOfComponent.changeoverOffset;
    this.oldDynamicMessages = this.stateOfComponent.oldDynamicMessages;
    this.oldOperationInfos = this.stateOfComponent.oldOperationInfos;
    this.oldScheduleRowIndex = this.stateOfComponent.oldScheduleRowIndex;
    this.scheduleDisplayIndex = this.stateOfComponent.scheduleDisplayIndex;
    this.timeDateLine = this.stateOfComponent.timeDateLine;
    this.timetableModePreview = this.stateOfComponent.timetableModePreview;
    this.typeDisplay1ModePreview = this.stateOfComponent.typeDisplay1ModePreview;
    this.typeDisplay2ModePreview = this.stateOfComponent.typeDisplay2ModePreview;
    this.monitorResponseData = this.stateOfComponent.monitorResponseData;
    this.isNetworkOK = this.stateOfComponent.isNetworkOK;
    this.emergencyMediaIdOld = this.stateOfComponent.emergencyMediaIdOld;
    this.areaSwitchingTiming = this.stateOfComponent.areaSwitchingTiming;
    this.filesData = this.stateOfComponent.filesData;
    this.isOnlyTextArea = this.stateOfComponent.isOnlyTextArea;
    this.isOnlyPictureArea = this.stateOfComponent.isOnlyPictureArea;
    this.mediaFilesDroppedOperation = this.stateOfComponent.mediaFilesDroppedOperation;
    this.mediaIdsToDelete = this.stateOfComponent.mediaIdsToDelete;

    if (this.schedules.length != 0 && !this.deviceSelected.isManuallySetOperatingSystem) {
      this.checkLessCurrentTime();
    }
    this.changeDetectorRef.detectChanges();
    if (!this.timetableSelected) {
      return;
    }
    this.drawTimetableService.changeStartState(true, ScreenCanvasIdEnum.TIMETABLE_OPERATION_MANAGER);
    if (this.isViewMonitorMode) {
      //this.drawPreviewModeMonitor();
    } else {
      this.drawPreviewModePreview();
    }
  }

  ngOnDestroy(): void {
    this.isPlayPreview = false;
    this.dataService.sendData([`${MODULE_NAME[FIELD_COMPONENT.TimetableOperationManagerComponent]}:isPlayPreview`, false]);
    this.clearAllBeforeLeave();
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.subscribesGetDataExternal.forEach(subscription => subscription?.unsubscribe());
    this.pauseTimerCheckLessCurrentTimeSubject.next();
    this.pauseTimerCheckLessCurrentTimeSubject.unsubscribe();
    this.resetTimerCheckLessCurrentTimeSubject.unsubscribe();
    this.pauseTimerGetDataEveryDaySubject.next();
    this.pauseTimerGetDataEveryDaySubject.unsubscribe();
    this.resetTimerGetDataEveryDaySubject.unsubscribe();
    this.startPreviewSubject.unsubscribe();
    this.pausePreviewSubject.unsubscribe();
    this.mediaService.deleteFolderMediaFromPC(FolderNameDropPDFEnum.EMERGENCY).toPromise();
    this.mediaService.deleteFolderMediaFromPC(FolderNameDropPDFEnum.DYNAMIC_MESSAGE).toPromise();
    this.store.dispatch(
      new SaveTimetableOperationManagerStateAction({
        isChangeLayout: true,
        isEnlargePreview: this.isEnlargePreview,
        isEditDynamicMessageMode: this.isEditDynamicMessageMode,
        isEditEmergencyMode: this.isEditEmergencyMode,
        isViewMonitorMode: this.isViewMonitorMode,
        isZoom: this.isZoom,
        isPan: this.isPan,
        deviceSelected: this.deviceSelected,
        devices: this.devices,
        schedules: this.schedules,
        scheduleColumns: this.scheduleColumns,
        activeScheduleRow: this.activeScheduleRow,
        timetableSelected: this.timetableSelected,
        dynamicMessages: this.dynamicMessages,
        emergencyData: this.emergencyData,
        buttonsPreviewDisplay1: this.buttonsPreviewDisplay1,
        buttonsPreviewDisplay2: this.buttonsPreviewDisplay2,
        templateSelectedTypeDisplay1: this.templateSelectedTypeDisplay1,
        templateSelectedTypeDisplay2: this.templateSelectedTypeDisplay2,
        linkAreasDisplay1: this.linkAreasDisplay1,
        linkAreasDisplay2: this.linkAreasDisplay2,
        currentIndexTimetableSchedule: this.currentIndexTimetableSchedule,
        scheduleOperationInfo: this.scheduleOperationInfo,
        scheduleRowIndex: this.scheduleRowIndex,
        timetablePositionRowDisplay1Set: this.timetablePositionRowDisplay1Set,
        timetablePositionRowDisplay2Set: this.timetablePositionRowDisplay2Set,
        isPlayPreview: this.isPlayPreview,
        operationInfoOptions: this.operationInfoOptions,
        changeoverOffset: this.changeoverOffset,
        oldDynamicMessages: this.oldDynamicMessages,
        oldOperationInfos: this.oldOperationInfos,
        oldScheduleRowIndex: this.oldScheduleRowIndex,
        scheduleDisplayIndex: this.scheduleDisplayIndex,
        timeDateLine: this.timeDateLine,
        timetableModePreview: this.timetableModePreview,
        typeDisplay1ModePreview: this.typeDisplay1ModePreview,
        typeDisplay2ModePreview: this.typeDisplay2ModePreview,
        monitorResponseData: this.monitorResponseData,
        isNetworkOK: this.isNetworkOK,
        emergencyMediaIdOld: this.emergencyMediaIdOld,
        areaSwitchingTiming: this.areaSwitchingTiming,
        filesData: this.filesData,
        isOnlyTextArea: this.isOnlyTextArea,
        isOnlyPictureArea: this.isOnlyPictureArea,
        mediaFilesDroppedOperation: this.mediaFilesDroppedOperation,
        mediaIdsToDelete: this.mediaIdsToDelete
      })
    );
  }

  /**
   * clear all
   */
  private clearAllBeforeLeave(): void {
    this.isPlayPreview = false;
    this.dataService.sendData([`${MODULE_NAME[FIELD_COMPONENT.TimetableOperationManagerComponent]}:isPlayPreview`, false]);
    // clear timeout
    this.clearTimeoutDisplay(this.timeoutsDisplay1);
    this.clearTimeoutDisplay(this.timeoutsDisplay2);
    this.drawTimetableService.changeStatePlayPause(
      this.isPlayPreview,
      this.canvasDisplay1Id,
      ScreenCanvasIdEnum.TIMETABLE_OPERATION_MANAGER
    );
    this.drawTimetableService.changeStatePlayPause(
      this.isPlayPreview,
      this.canvasDisplay2Id,
      ScreenCanvasIdEnum.TIMETABLE_OPERATION_MANAGER
    );
    // clear all thread
    this.drawTimetableService.clearAllIntervalDrawsNewsDisplay1();
    this.drawTimetableService.clearAllIntervalDrawsNewsDisplay2();
    this.subscribesGetDataExternal.forEach(subscription => subscription?.unsubscribe());
    // clear all thread draw template
    if (this.timetableSelected?.templateDisplay1s) {
      this.drawTimetableService.clearAllThreadDrawTemplate(
        _.get(this.timetableSelected?.templateDisplay1s, `[${this.templateSelectedTypeDisplay1}]`, undefined),
        this.canvasDisplay1Id,
        ScreenCanvasIdEnum.TIMETABLE_OPERATION_MANAGER
      );
    }
    if (this.timetableSelected?.templateDisplay2s) {
      this.drawTimetableService.clearAllThreadDrawTemplate(
        _.get(this.timetableSelected?.templateDisplay2s, `[${this.templateSelectedTypeDisplay2}]`, undefined),
        this.canvasDisplay2Id,
        ScreenCanvasIdEnum.TIMETABLE_OPERATION_MANAGER
      );
    }
    this.drawTimetableService.clearTimeoutsStopDurationArea(true, ScreenCanvasIdEnum.TIMETABLE_OPERATION_MANAGER);
  }

  /**
   * select device
   *
   * @param device
   * @param index
   * @param $event
   * @returns
   */
  public selectDevice(device: Device, index: number, $event?: any) {
    if (
      !device ||
      $event?.target.id == 'timetable-operation-checkbox-device' ||
      $event?.target.id == `timetable-operation-device${index}` ||
      $event?.target.id == 'timetable-operation-toggle-container' ||
      this.isEditDynamicMessageMode ||
      this.isEditEmergencyMode
    ) {
      return;
    }
    if (this.deviceSelected?.captureUrl) this.deviceSelected.captureUrl = null;
    this.lastTimeUpdate = null;
    this.timeByImg = null;
    this.pauseTimerCheckLessCurrentTimeSubject.next();
    this.isPlayPreview = false;
    this.dataService.sendData([`${MODULE_NAME[FIELD_COMPONENT.TimetableOperationManagerComponent]}:isPlayPreview`, false]);
    this.monitorResponseData = undefined;
    this.timetableModePreview = undefined;
    this.isViewMonitorMode = this.commonObject.isViewMonitorMode;
    this.dataService.sendData([
      `${MODULE_NAME[FIELD_COMPONENT.TimetableOperationManagerComponent]}:isViewMonitorMode`,
      this.isViewMonitorMode
    ]);
    this.updateStateItemOnMenu.next();
    if (!this.isViewMonitorMode) {
      Helper.clearNodeChild(this.divContainCanvas1.nativeElement);
      if (this.isDisplay2) {
        Helper.clearNodeChild(this.divContainCanvas2.nativeElement);
      }
    }
    this.lastTimeUpdate = null;
    this.timeByImg = null;
    this.clearTimeoutDisplay(this.timeoutsDisplay1);
    this.clearTimeoutDisplay(this.timeoutsDisplay2);
    this.drawTimetableService.pausePreview(this.canvasDisplay1Id, ScreenCanvasIdEnum.TIMETABLE_OPERATION_MANAGER);
    this.drawTimetableService.pausePreview(this.canvasDisplay2Id, ScreenCanvasIdEnum.TIMETABLE_OPERATION_MANAGER);
    this.drawTimetableService.clearTimeoutsStopDurationArea(true, ScreenCanvasIdEnum.TIMETABLE_OPERATION_MANAGER);
    this.panzoomDisplay1 = undefined;
    this.panzoomDisplay2 = undefined;
    this.templateSelectedTypeDisplay1 = DestinationEnum.MAIN;
    this.templateSelectedTypeDisplay2 = DestinationEnum.MAIN;
    this.timetableSelected = undefined;
    this.schedules = [];
    this.scheduleColumns = [];
    this.activeScheduleRow = new ActiveScheduleRow();
    this.timetablePositionRowDisplay1Set = new Set();
    this.timetablePositionRowDisplay2Set = new Set();
    this.isPan = false;
    this.isZoom = false;
    this.currentIndexTimetableSchedule = 0;
    this.deviceSelected = device;
    this.commonObject.timetableOperationDeviceId = this.deviceSelected.id;
    this.store.dispatch(
      new SaveMainStateAction({
        common: this.commonObject
      })
    );
    // check network if active mode preview
    if (!this.isViewMonitorMode) {
      this.checkNetWorkAndGetDataPreviewModeMonitor(true);
    }
    const currentDate = this.getCurrentDateByConditions();
    forkJoin({
      dynamicMessageData: this.dynamicMessageService.getDynamicMessageForMultiDeviceByDeviceId([this.deviceSelected.id], currentDate),
      emergencyData: this.emergencyDataService.getEmergencyData(),
      updateDeliveryStatus: this.deliveryStatusTimetableStatus.updateDeliveryStatusForDeviceForTimetableOperation(
        new DeviceInformation(this.deviceSelected.id, this.deviceSelected.timeDateLine)
      ),
      areaSwitchingTiming: this.timetableContentDayService.getAreaSwitchingTiming(this.deviceSelected.id)
    }).subscribe(
      data => {
        this.areaSwitchingTiming = data.areaSwitchingTiming ?? Constant.TIME_SWITCH_AREA_DEFAULT;
        this.dynamicMessages = data.dynamicMessageData.map(dynamicMessageData => {
          return this.convertDataDynamicMessageFromServer(dynamicMessageData);
        });
        if (data.emergencyData.length > 0) {
          this.emergencyData = data.emergencyData[0];
          if (this.emergencyData.emergencyImageId == -1 || !this.emergencyData.emergencyImageId || !this.emergencyData.media) {
            this.emergencyData.media = null;
            this.emergencyData.urlImage = Constant.EMPTY;
          }
        } else {
          this.emergencyData = new EmergencyData(Constant.EMPTY, -1, Constant.EMPTY);
        }
        this.emergencyMediaIdOld = this.emergencyData.emergencyImageId;
        this.timetableContentDayService.getTimeDateLine(this.deviceSelected.id).subscribe(data => {
          this.timeDateLine = data ?? this.TIME_RESET;
          this.getDataDeviceEveryDay();
          const currentDate = this.getCurrentDateByConditions();
          this.timetableService.getTimetableByDeviceIdAndCurrentDate(this.deviceSelected.id, currentDate).subscribe(
            data => {
              if (!data) {
                if (this.isViewMonitorMode) {
                  this.timetableModePreview = _.cloneDeep(this.timetableSelected);
                  this.typeDisplay1ModePreview = _.cloneDeep(this.templateSelectedTypeDisplay1);
                  this.typeDisplay2ModePreview = _.cloneDeep(this.templateSelectedTypeDisplay2);
                  this.checkNetWorkAndGetDataPreviewModeMonitor();
                }
                return;
              }
              this.timetableSelected = Helper.convertDataTimetable(data);
              this.timetableSelected.timetableDetails = Helper.convertTimetableDetails(data.timetableDetails);
              if (data.timetableSchedule.items && data.timetableSchedule.itemNames) {
                this.timetableSelected.timetableSchedule = Helper.convertTimetableSchedule(
                  data.timetableSchedule,
                  true,
                  Helper.convertSecondsToString(this.timeDateLine)
                );
                this.changeoverOffset = data.timetableSchedule.changeoverOffset;
                if (this.timetableSelected.timetableSchedule.headers.length > 0) {
                  Helper.updateLanguageHeaders(this.timetableSelected.timetableSchedule.headers, this.translateService);
                }
                this.scheduleColumns = this.timetableSelected.timetableSchedule.headers.filter(header => header != null);
                const headerColumnTime = this.timetableSelected.timetableSchedule.headers[ReferencePositionTimetableColumnEnum.TIME];
                this.timetableSelected.timetableSchedule.itemDetails.forEach(itemDetail => {
                  let schedule = new Schedule();
                  for (let i = 0; i < this.scheduleColumns.length; i++) {
                    schedule.list.push(itemDetail?.items[i]);
                  }
                  const dataColumnTime = schedule.list[ReferencePositionTimetableColumnEnum.TIME];
                  schedule.convertedTime = headerColumnTime != null ? this.convertTime(dataColumnTime) : dataColumnTime;
                  this.schedules.push(schedule);
                });
                // get operation info for schedules
                if (data.timetableSchedule['scheduleOperationInfo']) {
                  this.scheduleOperationInfo = this.convertDataScheduleOperationInfo(data.timetableSchedule['scheduleOperationInfo']);
                  this.schedules.forEach((schedule, index) => {
                    schedule.operationInfo = this.operationInfoOptions.find(
                      operationInfo => operationInfo.id == this.scheduleOperationInfo.operationInfo[index]
                    );
                  });
                }
                // get schedule row
                this.linkAreasDisplay1 = Helper.getAllAreaTemplateForDisplay(this.timetableSelected.templateDisplay1s).filter(
                  area => !area.isFix
                );
                this.getAllAreasReferencePositionRow(DisplaysEnum.DISPLAY_1).forEach(area => {
                  this.timetablePositionRowDisplay1Set.add(area.referencePositionRow);
                });
                if (this.isDisplay2 && this.timetableSelected.templateDisplay2s) {
                  this.linkAreasDisplay2 = Helper.getAllAreaTemplateForDisplay(this.timetableSelected.templateDisplay2s).filter(
                    area => !area.isFix
                  );
                  this.getAllAreasReferencePositionRow(DisplaysEnum.DISPLAY_2).forEach(area => {
                    this.timetablePositionRowDisplay2Set.add(area.referencePositionRow);
                  });
                }
                if (this.schedules.length != 0) {
                  // get display index for schedules
                  this.scheduleDisplayIndex = this.convertDataScheduleDisplayIndex(data.timetableSchedule['scheduleDisplayIndex']);
                  if (!this.deviceSelected.isManuallySetOperatingSystem) {
                    this.checkLessCurrentTime();
                  } else {
                    if (Object.values(this.scheduleDisplayIndex.displayIndex)?.every(value => value == -1)) {
                      this.activeScheduleRow = this.getActiveScheduleRow(this.schedules);
                    } else {
                      let schedules = [];
                      const values: number[] = Object.values(_.cloneDeep(this.scheduleDisplayIndex.displayIndex));
                      values.forEach(index => {
                        schedules.push(this.schedules[index]);
                      });
                      if (schedules.length > 0) {
                        this.activeScheduleRow = this.getActiveScheduleRow(schedules);
                      }
                    }
                    this.getScheduleRowIndex();
                  }
                }
              }
              this.buttonsPreviewDisplay1 = this.getButtonsPreview(this.timetableSelected.displayTemplate1);
              this.buttonsPreviewDisplay2 = this.getButtonsPreview(this.timetableSelected.displayTemplate2);
              this.drawTimetableService.changeStartState(true, ScreenCanvasIdEnum.TIMETABLE_OPERATION_MANAGER);
              this.timetableModePreview = _.cloneDeep(this.timetableSelected);
              this.typeDisplay1ModePreview = _.cloneDeep(this.templateSelectedTypeDisplay1);
              this.typeDisplay2ModePreview = _.cloneDeep(this.templateSelectedTypeDisplay2);
              if (this.isViewMonitorMode) {
                this.checkNetWorkAndGetDataPreviewModeMonitor();
              } else {
                this.drawPreviewModePreview();
              }
            },
            () => this.handleErrorFromApi()
          );
        });
      },
      () => this.handleErrorFromApi()
    );
  }

  /**
   * check active schedule row
   *
   * @param schedule
   * @returns
   */
  public checkActiveScheduleRow(schedule: Schedule) {
    if (schedule.isLessCurrentTime) {
      return false;
    }
    return Object.values(this.activeScheduleRow).some(sch => _.isEqual(schedule, sch));
  }

  /**
   * check device
   *
   * @param device
   */
  public changeCheckDevice(device: Device) {
    if (this.isEditDynamicMessageMode || this.isEditEmergencyMode) {
      return;
    }
    device.isChecked = !device.isChecked;
  }

  /**
   * edit dynamic message
   *
   * @param dynamicMessage
   */
  public editDynamicMessage(dynamicMessage: DynamicMessage) {
    if (dynamicMessage.pictureArea || !this.validateActionCommon()) {
      return;
    }
    dynamicMessage.isEdit = true;
    if (!dynamicMessage.message) {
      dynamicMessage.message = Constant.EMPTY;
    }
  }

  /**
   * open dynamic message
   */
  private openDynamicMessage() {
    const checkedDevices = this.devices.filter(device => device.isChecked);
    if (!this.validateClickMenuAction()) {
      return;
    }
    if (checkedDevices.length == 0) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-message.error-title'),
          text: this.translateService.instant('dialog-message.timetable-operation.choose-devices')
        }
      });
      return;
    }
    this.dynamicMessages = [];
    this.isEditDynamicMessageMode = true;
    this.updateStateItemOnMenu.next();
    const currentDate = this.getCurrentDateByConditions();
    this.dynamicMessageService
      .getDynamicMessageForMultiDeviceByDeviceId(
        checkedDevices.map(device => device.id),
        currentDate
      )
      .subscribe(
        data => {
          this.dynamicMessages = this.handleDataDynamicMessage(data);
          this.handleShowColumnTextAndMedia();
          this.clearDataMediaDropToDynamicMessages();
          this.oldDynamicMessages = _.cloneDeep(this.dynamicMessages);
          if (checkedDevices.includes(this.deviceSelected)) {
            this.reDrawDynamicMessage(this.dynamicMessages);
          }
        },
        () => this.handleErrorFromApi()
      );
  }

  /**
   * change dynamic message by enter key
   *
   * @param dynamicMessage
   * @param index
   * @param $event
   * @returns
   */
  public changeDynamicMessageEnterKey(dynamicMessage: DynamicMessage, index: number, $event: any) {
    if (!$event) {
      $event.preventDefault();
      return;
    }
    if (this.isPlayPreview) {
      $event.preventDefault();
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-message.error-title'),
          text: Helper.getErrorMessage(ErrorEnum.PREVIEW_IS_PLAYING, null, null, this.translateService)
        }
      });
      return;
    }
    // key enter
    if ($event.keyCode == this.ENTER_KEY_CODE) {
      if (dynamicMessage.message.length > this.MAX_LENGTH_VALUE) {
        this.propertiesName.dynamicMessage = this.translateService.instant('dialog-message.timetable-operation.dynamic-message');
        this.dialogService.showDialog(
          DialogMessageComponent,
          {
            data: {
              title: this.translateService.instant('dialog-message.error-title'),
              text: Helper.getErrorMessage(
                ErrorEnum.MAX_LENGTH,
                this.propertiesName.dynamicMessage,
                this.MAX_LENGTH_VALUE,
                this.translateService
              )
            },
            autoFocus: false
          },
          () => {
            document.getElementById(`dynamicMessageInput${index}`).focus();
          }
        );
        return;
      }
      dynamicMessage.isEdit = false;
    }
  }

  /**
   * change dynamic message by keyboard
   *
   * @param index
   */
  public changeDynamicMessage(index: number): void {
    if (this.isPlayPreview) {
      return;
    }
    this.dynamicMessages[index].isChanged = this.oldDynamicMessages[index].message != this.dynamicMessages[index].message;
    if (!this.isViewMonitorMode) {
      this.drawTimetableService.clearTimeoutsStopDurationArea(false, ScreenCanvasIdEnum.TIMETABLE_OPERATION_MANAGER, [
        LinkDataTextEnum.DYNAMIC_MESSAGE
      ]);
      this.drawDynamicMessages(this.dynamicMessages.filter(dynamic => dynamic.device.id == this.deviceSelected.id));
    }
  }

  /**
   * open emergency
   */
  private openEmergency() {
    if (!this.validateClickMenuAction()) {
      return;
    }
    if (this.devices.length <= 0) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-message.error-title'),
          text: this.translateService.instant('dialog-message.timetable-operation.choose-devices')
        }
      });
      return;
    }
    let checkedDevices = this.devices.filter(device => device.isChecked);
    if (checkedDevices.length == 0) {
      this.dialogService.showDialog(
        DialogConfirmComponent,
        {
          data: {
            text: this.translateService.instant('dialog-confirm.timetable-operation-manager.text-select-device'),
            button1: this.translateService.instant('dialog-confirm.timetable-operation-manager.button-1'),
            button2: this.translateService.instant('dialog-confirm.timetable-operation-manager.button-2')
          }
        },
        result => {
          if (!result) {
            return;
          }
          this.devices.forEach(device => (device.isChecked = true));
          this.openDialogEmergencyMode(this.devices);
        }
      );
    } else {
      this.openDialogEmergencyMode(checkedDevices);
    }
  }

  /**
   * Validate click menu action
   *
   * @returns true if conditions is passed
   */
  private validateClickMenuAction(): boolean {
    if (this.isPlayPreview) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-message.error-title'),
          text: Helper.getErrorMessage(ErrorEnum.PREVIEW_IS_PLAYING, null, null, this.translateService)
        }
      });
      return false;
    }
    if (this.isEditDynamicMessageMode || this.isEditEmergencyMode) {
      return false;
    }
    return true;
  }

  /**
   * open popup emergency mode
   *
   * @param checkedDevices
   */
  private openDialogEmergencyMode(checkedDevices: Device[]) {
    this.dialogService.showDialog(DialogEmergencyModeComponent, {}, result => {
      // click Cancel
      if (result.length == 0) {
        return;
      }
      // click Off
      if (!result) {
        this.updateEmergencyModeForDevices(checkedDevices, false);
        return;
      }
      // click Edit
      this.isEditEmergencyMode = true;
      this.drawTimetableService.setDataIsOnEmergency(this.isEditEmergencyMode);
      this.updateStateItemOnMenu.next();
      this.emergencyDataService.getEmergencyData().subscribe(
        data => {
          if (data.length == 0) {
            this.emergencyData = new EmergencyData(Constant.EMPTY, -1, Constant.EMPTY);
            this.drawTimetableService.setDataPreviewTimetableOperationManager(null, null, null, this.emergencyData);
            this.emergencyMediaIdOld = this.emergencyData.emergencyImageId;
            return;
          }
          this.emergencyData = data[0];
          if (this.emergencyData.emergencyImageId == -1 || !this.emergencyData.emergencyImageId || !this.emergencyData.media) {
            this.emergencyData.media = null;
            this.emergencyData.urlImage = Constant.EMPTY;
          }
          this.emergencyMediaIdOld = this.emergencyData.emergencyImageId;
          this.drawTimetableService.setDataPreviewTimetableOperationManager(null, null, null, this.emergencyData);
          if (checkedDevices.includes(this.deviceSelected)) {
            this.reDrawEmergency(this.emergencyData);
          }
        },
        () => this.handleErrorFromApi()
      );
    });
  }

  /**
   * update emergency mode for devices
   *
   * @param checkedDevices
   * @param isOnEmergencyMode
   */
  private updateEmergencyModeForDevices(checkedDevices: Device[], isOnEmergencyMode: boolean) {
    const registrationIds = checkedDevices.map(device => device.registrationId);
    if (isOnEmergencyMode) {
      if (!this.emergencyData.emergencyImageId) {
        this.mediaService.uploadEmergencyMedia(this.emergencyData.media as any).subscribe(
          mediaResponse => {
            if (!mediaResponse) {
              this.handleErrorFromApi();
              return;
            }
            if (mediaResponse.id == Constant.ERROR_CODE_MAX_LENGTH_NAME) {
              this.dialogService.showDialog(DialogMessageComponent, {
                data: {
                  title: this.translateService.instant('dialog-message.error-title'),
                  text: Helper.formatString(
                    this.translateService.instant('timetable-operation-manager.message.length-file'),
                    `${Constant.MAX_LENGTH_MEDIA_NAME}`
                  )
                }
              });
              return;
            }
            this.emergencyData.media = mediaResponse;
            this.emergencyData.emergencyImageId = mediaResponse.id as number;
            this.emergencyData.urlImage = mediaResponse.url;
            this.emergencyMediaIdOld = this.emergencyData.emergencyImageId;
            this.drawTimetableService.setDataPreviewTimetableOperationManager(null, null, null, this.emergencyData);
            this.handleDataEmergency(registrationIds);
          },
          error => this.handleErrorFromCustomerApi('an-error', error)
        );
      } else {
        this.handleDataEmergency(registrationIds);
      }
    } else {
      const payload = {
        id: registrationIds,
        timeoutSec: this.DEFAULT_TIMEOUT
      };
      this.deviceService.offEmergencyMode(payload).subscribe(
        async () => {
          this.executingService.executing();
          const deviceCompletedRegistrationIds = await this.getDevicesSuccessForDelivery(registrationIds, false);
          if (deviceCompletedRegistrationIds.length == 0) {
            this.executingService.executed();
            this.handleErrorFromCustomerApi('off-emergency-mode-failed');
            return;
          }
          this.updateEmergencyMode(deviceCompletedRegistrationIds, false);
          this.toast.success(this.translateService.instant('timetable-operation-manager.delivery-success'), '');
        },
        error => this.handleErrorFromCustomerApi('off-emergency-mode-failed', error)
      );
    }
  }

  /**
   * Handle data emergency
   *
   * @param registrationIds
   */
  private handleDataEmergency(registrationIds: string[]): void {
    this.mediaService.getMediaUrlToDelivery(this.emergencyData.media ? this.emergencyData.media.id : -1).subscribe(
      dataResponse => {
        const payload = {
          id: registrationIds,
          message: Helper.encodeHTML(this.emergencyData.emergencyText),
          image: dataResponse['url'],
          timeoutSec: this.DEFAULT_TIMEOUT,
          req_id: uniqueId()
        };
        if (this.emergencyData.emergencyText == '' && payload[this.IMAGE_ATTRIBUTE] == '') {
          this.dialogService.showDialog(DialogMessageComponent, {
            data: {
              title: this.translateService.instant('dialog-message.error-title'),
              text: this.translateService.instant('dialog-message.timetable-operation.text-image-not-empty')
            }
          });
          return;
        }
        this.deviceService.onEmergencyMode(payload).subscribe(
          async () => {
            this.executingService.executing();
            const deviceCompletedRegistrationIds = await this.getDevicesSuccessForDelivery(registrationIds, false, payload);
            if (deviceCompletedRegistrationIds.length == 0) {
              this.executingService.executed();
              this.handleErrorFromCustomerApi('delivery-emergency-failed');
              return;
            }
            this.updateEmergencyMode(deviceCompletedRegistrationIds, true);
            // delete old emergency image after clear in GUI
            if (
              this.emergencyMediaIdOld &&
              this.emergencyMediaIdOld != -1 &&
              this.emergencyMediaIdOld != this.emergencyData.emergencyImageId
            ) {
              this.mediaService.deleteEmergencyImage(this.emergencyMediaIdOld).toPromise();
            }
            this.saveEmergency();
            this.toast.success(this.translateService.instant('timetable-operation-manager.delivery-success'), '');
          },
          error => this.handleErrorFromCustomerApi('delivery-emergency-failed', error)
        );
      },
      error => this.handleErrorFromCustomerApi('delivery-message-failed', error)
    );
  }

  /**
   * update emergency mode
   *
   * @param registrationIds
   * @param isOnEmergency
   * @returns
   */
  private updateEmergencyMode(registrationIds: string[], isOnEmergency: boolean) {
    if (!registrationIds || registrationIds.length == 0) {
      return;
    }
    this.deviceService.updateEmergencyMode(registrationIds, isOnEmergency).subscribe(
      deviceIds => {
        if (!isOnEmergency) {
          this.executingService.executed();
          this.clearAreasEmergency();
        }
        deviceIds.forEach(deviceId => {
          this.devices.find(device => device.id == deviceId).isOnEmergency = isOnEmergency;
        });
      },
      () => this.handleErrorFromApi()
    );
  }

  /**
   * save emergency data
   */
  private saveEmergency() {
    let emergencyUpdate = new EmergencyData(this.emergencyData.emergencyText, this.emergencyData.emergencyImageId);
    emergencyUpdate.id = this.emergencyData.id;
    this.emergencyDataService.save(emergencyUpdate).subscribe(
      data => {
        this.executingService.executed();
        this.emergencyData.id = data.id;
        this.drawTimetableService.setDataPreviewTimetableOperationManager(null, null, null, this.emergencyData);
        this.emergencyMediaIdOld = this.emergencyData.emergencyImageId;
      },
      () => this.handleErrorFromApi()
    );
  }

  /**
   * change emergency text
   *
   * @param emergencyData
   * @returns
   */
  changeEmergencyText(emergencyData: EmergencyData): void {
    if (this.isPlayPreview || this.isViewMonitorMode) {
      return;
    }
    this.drawEmergency(emergencyData, true);
  }

  /**
   * check prevent emergency text
   *
   * @param $event
   * @returns
   */
  public checkPreventEmergencyText($event: any) {
    if (this.isPlayPreview) {
      $event.preventDefault();
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-message.error-title'),
          text: Helper.getErrorMessage(ErrorEnum.PREVIEW_IS_PLAYING, null, null, this.translateService)
        }
      });
    }
  }

  /**
   * drag to set current schedule
   *
   * @param $event
   * @param index
   * @returns
   */
  public drag($event: any, index: any) {
    if ($event.target.innerText == Constant.EMPTY || this.schedules.length == 1 || !this.deviceSelected.isManuallySetOperatingSystem) {
      $event.preventDefault();
      return;
    } else if (this.isPlayPreview) {
      $event.preventDefault();
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-message.error-title'),
          text: Helper.getErrorMessage(ErrorEnum.PREVIEW_IS_PLAYING, null, null, this.translateService)
        }
      });
      return;
    }
    $event.dataTransfer.setData(this.ROW_NAME, $event.target.innerText);
    $event.dataTransfer.setData(this.INDEX_DRAG, index);
  }

  /**
   * drop current index
   *
   * @param $event
   */
  public drop($event: any) {
    $event.preventDefault();
    const tableBody = document.getElementById(this.tableBodyId);
    const rowName = $event.dataTransfer.getData(this.ROW_NAME);
    const indexDrag = $event.dataTransfer.getData(this.INDEX_DRAG);
    for (let index = 0; index < tableBody.children.length; index++) {
      if ($event.target.parentElement != tableBody.children.item(index)) {
        continue;
      }
      this.schedules[indexDrag].isChangedIndex = false;
      for (let i = 0; i < this.MAX_CURRENT_INDEX; i++) {
        if (this.rowName[i].name == rowName) {
          this.handleDropSchedule(
            this.schedules[index],
            this.schedules.find(schedule => schedule == this.activeScheduleRow[`${this.CURRENT}_${i}`]),
            indexDrag
          );
          this.schedules[index].isChangedIndex = this.oldScheduleRowIndex[`${this.INDEX_CURRENT}_${i}`] != index;
          this.activeScheduleRow[`${this.CURRENT}_${i}`] = this.schedules[index];
          break;
        }
      }
    }
    this.drawTimetableService.changeStartState(true, ScreenCanvasIdEnum.TIMETABLE_OPERATION_MANAGER);
    this.drawSchedule();
  }

  /**
   * handle drop schedule
   *
   * @param scheduleTarget
   * @param scheduleDrag
   * @param indexDrag
   */
  private handleDropSchedule(scheduleTarget: Schedule, scheduleDrag: Schedule, indexDrag: any) {
    const rowNameDrag = scheduleDrag.rowName;
    scheduleDrag.rowName = scheduleTarget.rowName;
    scheduleTarget.rowName = rowNameDrag;
    for (let i = 0; i < this.MAX_CURRENT_INDEX; i++) {
      if (this.activeScheduleRow[`${this.CURRENT}_${i}`] == scheduleTarget) {
        this.schedules[indexDrag].isChangedIndex = this.oldScheduleRowIndex[`${this.INDEX_CURRENT}_${i}`] != indexDrag;
        this.activeScheduleRow[`${this.CURRENT}_${i}`] = scheduleDrag;
        break;
      }
    }
  }

  /**
   * drop picture for emergency data
   *
   * @param $event
   * @returns
   */
  public dropPictureForEmergency($event: any) {
    if (
      $event.dataTransfer.getData(Constant.MEDIA_VALUE) == '' ||
      JSON.parse($event.dataTransfer.getData(Constant.IS_MEDIA_IN_STATION_CONTENT_FOLDER)) ||
      JSON.parse($event.dataTransfer.getData(Constant.IS_MEDIA_IN_LCD_LAYOUT_EDITOR)) ||
      JSON.parse($event.dataTransfer.getData(Constant.FOLDER_INDEX_WORD_EDITOR))
    ) {
      return;
    }
    if (!this.validateActionCommon()) {
      return;
    }
    $event.preventDefault();
    var mediaObject = JSON.parse($event.dataTransfer.getData(Constant.MEDIA_VALUE));
    let media = Helper.convertMediaData(mediaObject);
    if (!Helper.isImage(media)) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-message.error-title'),
          text: this.translateService.instant('dialog-message.timetable-operation.invalid-file')
        }
      });
      return;
    }
    media.name = Helper.convertMediaNameBackWard(media.name, media.mediaNameEncode);
    this.emergencyData.media = media;
    this.emergencyData.urlImage = media.url;
    this.emergencyData.emergencyImageId = media.id;
    this.drawEmergency(this.emergencyData, false, true);
  }

  /**
   * Drop media from pc to emergency
   *
   * @param mediaFile
   * @returns
   */
  public async dropMediaFromPCToEmergency(mediaFile: any) {
    if (!this.validateActionCommon()) {
      return;
    }
    if (!Helper.isImageFile(mediaFile[Constant.FILE_MEDIA_OBJECT])) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-message.error-title'),
          text: this.translateService.instant('dialog-message.timetable-operation.invalid-file')
        }
      });
      return;
    }

    // handle file drop
    let fileDrop = mediaFile[0][0];
    const type = mediaFile[Constant.FILE_MEDIA_OBJECT][Constant.TYPE_ATTRIBUTE];
    // file is pdf
    if (type.toLowerCase() == TypeMediaFileEnum.PDF) {
      const numberOfPage = await this.mediaService
        .getNumberOfPagePdf(new File([fileDrop], fileDrop[Constant.NAME_ATTRIBUTE]))
        .toPromise()
        .catch(error => {
          Helper.handleError(error, this.translateService, this.dialogService);
          return 0;
        });
      if (numberOfPage > 1) {
        this.dialogService.showDialog(
          DialogConfirmComponent,
          {
            data: {
              text: this.translateService.instant('dialog-confirm.timetable-operation-manager.confirm-convert-file-pdf'),
              button1: this.translateService.instant('dialog-confirm.timetable-operation-manager.button-1'),
              button2: this.translateService.instant('dialog-confirm.timetable-operation-manager.button-2')
            }
          },
          result => {
            if (!result) {
              return;
            }
            this.handleAfterDropMediaToEmergency(fileDrop, mediaFile);
          }
        );
      } else if (numberOfPage == 1) {
        this.handleAfterDropMediaToEmergency(fileDrop, mediaFile);
      }
    } else {
      this.handleAfterDropMediaToEmergency(fileDrop, mediaFile);
    }
  }
  /**
   * handle After Drop Media To Emergency
   * @param fileDrop
   * @param mediaFile
   * @returns
   */
  private async handleAfterDropMediaToEmergency(fileDrop: any, mediaFile: any): Promise<void> {
    let fileFromPC: Media = null;
    const type = mediaFile[Constant.FILE_MEDIA_OBJECT][Constant.TYPE_ATTRIBUTE];
    // file is pdf
    if (type.toLowerCase() == TypeMediaFileEnum.PDF) {
      let isErrorFonts = await this.mediaService
        .checkFontsConvert(new File([fileDrop], fileDrop[Constant.NAME_ATTRIBUTE]))
        .toPromise()
        .catch(error => {
          this.dialogService.showDialog(DialogMessageComponent, {
            data: {
              title: this.translateService.instant('index-word-editor.msg.title-error'),
              text: this.translateService.instant('lcd-layout-editor.msg.common-error')
            }
          });
          return null;
        });
      if (isErrorFonts) {
        this.dialogService.showDialog(
          DialogConfirmComponent,
          {
            data: {
              text: this.translateService.instant('lcd-layout-editor.msg.fonts-error-convert-pdf'),
              button1: this.translateService.instant('lcd-layout-editor.yes'),
              button2: this.translateService.instant('lcd-layout-editor.no')
            },
            autoFocus: false
          },
          async result => {
            if (!result) {
              return;
            }
            fileFromPC = await this.mediaService
              .convertPDFToImage(new File([fileDrop], fileDrop[Constant.NAME_ATTRIBUTE]), FolderNameDropPDFEnum.EMERGENCY)
              .toPromise()
              .catch(error => {
                this.dialogService.showDialog(DialogMessageComponent, {
                  data: {
                    title: this.translateService.instant('dialog-error.title'),
                    text: this.translateService.instant('timetable-operation-manager.pdf-cannot-display')
                  }
                });
                return null;
              });

            if (fileFromPC == null) {
              return;
            }
            this.showImgEmergencyAfterDrop(fileDrop, fileFromPC, mediaFile);
          }
        );
      } else {
        fileFromPC = await this.mediaService
          .convertPDFToImage(new File([fileDrop], fileDrop[Constant.NAME_ATTRIBUTE]), FolderNameDropPDFEnum.EMERGENCY)
          .toPromise()
          .catch(error => {
            this.dialogService.showDialog(DialogMessageComponent, {
              data: {
                title: this.translateService.instant('dialog-error.title'),
                text: this.translateService.instant('timetable-operation-manager.pdf-cannot-display')
              }
            });
            return null;
          });

        if (fileFromPC == null) {
          return;
        }
        this.showImgEmergencyAfterDrop(fileDrop, fileFromPC, mediaFile);
      }
    } else {
      this.showImgEmergencyAfterDrop(fileDrop, fileFromPC, mediaFile, true);
    }
  }

  /**
   * showImgEmergencyAfterDrop
   * @param fileDrop
   * @param fileFromPC
   * @param mediaFile
   * @returns
   */
  private async showImgEmergencyAfterDrop(fileDrop, fileFromPC, mediaFile, isCheck?) {
    let imageInfo = await this.getImageInformation(fileDrop);
    // validate unsupported file format
    if (isCheck && imageInfo[Constant.ERROR_ELEMENT]) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-message.error-title'),
          text: this.translateService.instant('dialog-error.unsupported-file-format')
        }
      });
      return;
    }
    this.emergencyData.emergencyImageId = undefined;
    this.emergencyData.urlImage = fileFromPC ? fileFromPC.url : mediaFile[Constant.FILE_MEDIA_OBJECT][Constant.URL_ATTRIBUTE];
    this.emergencyData.media = fileDrop;
    this.emergencyData.media.url = fileFromPC ? fileFromPC.url : mediaFile[Constant.FILE_MEDIA_OBJECT][Constant.URL_ATTRIBUTE];
    this.drawEmergency(this.emergencyData, false, true);
  }

  /**
   * Get image information
   *
   * @param media
   * @returns
   */
  private getImageInformation(media: any): any {
    return new Promise((resolve, reject) => {
      try {
        const fileReader = new FileReader();
        fileReader.onload = () => {
          const img = new Image();
          img.onload = () => {
            resolve({ width: img.width, height: img.height });
          };
          img.onerror = () => {
            resolve({ error: Constant.ERROR_ELEMENT });
          };
          img.src = fileReader.result as string;
        };
        fileReader.readAsDataURL(media);
      } catch (e) {
        reject(e);
      }
    });
  }

  /**
   * Validate action common
   *
   * @returns true: monitor mode and play preview is inactive
   */
  private validateActionCommon(): boolean {
    if (this.isPlayPreview) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-message.error-title'),
          text: Helper.getErrorMessage(ErrorEnum.PREVIEW_IS_PLAYING, null, null, this.translateService)
        }
      });
      return false;
    }
    return true;
  }

  /**
   * allow drop
   *
   * @param e
   */
  public allowDrop(e) {
    e.preventDefault();
  }

  /**
   * change set operating system (ON / OFF)
   *
   * @param device
   * @param index
   * @returns
   */
  public changeTimetableMode(device: Device, index: number) {
    if (this.isEditDynamicMessageMode || this.isEditEmergencyMode) {
      return;
    }
    const payloadTimetableMode = {
      device: device.registrationId,
      auto_mode: device.isManuallySetOperatingSystem
    };
    this.apiCustomerService.changeTimetableMode(payloadTimetableMode).subscribe(
      () => {
        this.executingService.executing();
        const payloadRealtime = {
          device: device.registrationId
        };
        const intervalCallAPI = interval(this.NUMBER_MILLISECONDS_CALL_API);
        const takeNumbers = intervalCallAPI.pipe(take(this.NUMBERS_OF_API_CALLS));
        const sub = takeNumbers
          .pipe(
            concatMap(num => {
              return this.handleDataDeliveryTimetableMode(payloadRealtime, sub, payloadTimetableMode, device, index, num);
            })
          )
          .subscribe(() => {});
      },
      () => this.handleErrorTimetableMode(device, this.TIMETABLE_MODE_ERROR_CODE)
    );
  }

  /**
   * Handle data delivery timetable mode
   *
   * @param payloadRealtime
   * @param sub
   * @param payloadTimetableMode
   * @param device
   * @param index
   * @param count
   * @returns
   */
  private handleDataDeliveryTimetableMode(
    payloadRealtime: any,
    sub: any,
    payloadTimetableMode: any,
    device: Device,
    index: number,
    count: number
  ): Promise<void> {
    return new Promise<void>(resolve => {
      this.apiCustomerService
        .getTimetableRealtimeStatus(payloadRealtime)
        .pipe(takeUntil(this.subject))
        .subscribe(
          realTimeData => {
            if (realTimeData && realTimeData[this.AUTOMATION_MODE] == payloadTimetableMode['auto_mode']) {
              sub.unsubscribe();
              this.deviceService.updateDataForOneDevice(device).subscribe(
                deviceUpdate => {
                  this.executingService.executed();
                  if (deviceUpdate) {
                    this.devices[index] = deviceUpdate;
                    if (deviceUpdate.registrationId == this.deviceSelected.registrationId) {
                      this.deviceSelected = deviceUpdate;
                    } else if (device != this.deviceSelected || this.schedules.length == 0) {
                      return;
                    }
                    if (this.isPlayPreview) {
                      this.pausePreview();
                    }
                    if (!this.deviceSelected.isManuallySetOperatingSystem) {
                      // ON
                      this.schedules.forEach(schedule => {
                        schedule.isChangedIndex = false;
                      });
                      this.currentIndexTimetableSchedule = 0;
                      this.checkLessCurrentTime();
                      return;
                    }
                    // OFF
                    this.pauseTimerCheckLessCurrentTimeSubject.next();
                    this.schedules.forEach(schedule => {
                      schedule.isLessCurrentTime = false;
                    });
                    this.getScheduleRowIndex();
                    if (this.schedules.length) {
                      this.scheduleDisplayIndex.displayIndex = _.cloneDeep(this.scheduleRowIndex);
                      this.scheduleDisplayIndexService
                        .saveScheduleDisplayIndex(Helper.convertDataScheduleDisplayIndexBackward(this.scheduleDisplayIndex))
                        .toPromise();
                    }
                    this.drawTimetableService.changeStartState(true, ScreenCanvasIdEnum.TIMETABLE_OPERATION_MANAGER);
                    this.drawSchedule();
                  } else {
                    this.handleErrorTimetableMode(device, this.TIMETABLE_MODE_ERROR_CODE);
                  }
                },
                () => this.handleErrorFromApi()
              );
            } else if (count == this.NUMBERS_OF_API_CALLS - 1) {
              this.executingService.executed();
              this.handleErrorTimetableMode(device, this.TIMETABLE_MODE_ERROR_CODE);
              this.subject.next();
            }
            resolve();
          },
          () => {
            if (count == this.NUMBERS_OF_API_CALLS - 1) {
              this.executingService.executed();
              this.handleErrorTimetableMode(device, 'call-realtime-failed');
              this.subject.next();
            } else {
              this.executingService.executing();
              resolve();
            }
          }
        );
    });
  }

  /**
   * Get schedule row index
   */
  private getScheduleRowIndex(): void {
    let rowIndex = {};
    for (let i = 0; i < this.MAX_CURRENT_INDEX; i++) {
      rowIndex[`${this.INDEX_CURRENT}_${i}`] = this.schedules.findIndex(
        schedule => schedule == this.activeScheduleRow[`${this.CURRENT}_${i}`]
      );
    }
    this.scheduleRowIndex = rowIndex as any;
    this.oldScheduleRowIndex = _.cloneDeep(this.scheduleRowIndex);
  }

  /**
   * Handle error timetable mode
   *
   * @param device
   * @param errorMessage
   */
  private handleErrorTimetableMode(device: Device, errorMessage: string): void {
    device.isManuallySetOperatingSystem = !device.isManuallySetOperatingSystem;
    this.handleErrorFromCustomerApi(errorMessage);
  }

  /**
   * change select operation info
   *
   * @param schedule
   * @param operationInformation
   * @param index
   */
  public changeSelectOperationInfo(schedule: Schedule, operationInformation: any, index: any) {
    if (!this.validateActionCommon()) {
      return;
    }
    schedule.operationInfo =
      operationInformation != null ? this.operationInfoOptions.find(operationInfo => operationInfo.id == operationInformation) : null;
    schedule.isChangedDelayInfo = this.scheduleOperationInfo.operationInfo[index] != schedule.operationInfo?.id;
    this.drawOperationInfos();
  }

  /**
   * draw operation infos
   */
  private drawOperationInfos(): void {
    this.drawTimetableService.clearTimeoutsStopDurationArea(false, ScreenCanvasIdEnum.TIMETABLE_OPERATION_MANAGER, [
      LinkDataTextEnum.OPERATION_INFO
    ]);
    this.drawTimetableService.changeStartState(true, ScreenCanvasIdEnum.TIMETABLE_OPERATION_MANAGER);
    // draw area display 1
    const areasOperationInfo1 = this.getAllAreasOperationInfo(DisplaysEnum.DISPLAY_1);
    let display1 = _.get(this.timetableSelected?.templateDisplay1s, `[${this.templateSelectedTypeDisplay1}]`, undefined);
    this.drawAreasOperationInfoForDisplay(display1, areasOperationInfo1, this.canvasDisplay1Id);

    if (this.isDisplay2) {
      // draw area display 2
      const areasOperationInfo2 = this.getAllAreasOperationInfo(DisplaysEnum.DISPLAY_2);
      let display2 = _.get(this.timetableSelected?.templateDisplay2s, `[${this.templateSelectedTypeDisplay2}]`, undefined);
      this.drawAreasOperationInfoForDisplay(display2, areasOperationInfo2, this.canvasDisplay2Id);
    }
  }

  /**
   * draw areas operation info for display
   * @param display
   * @param areasOperationInfo
   * @param canvasDisplayId
   */
  private drawAreasOperationInfoForDisplay(display: Template, areasOperationInfo: Area[], canvasDisplayId: string): void {
    // draw layer off
    let areasOperationInfoLayerOff = Helper.getAreasOfLayerOnOff(areasOperationInfo, display, this.areaSwitchingTiming);
    this.drawTimetableService.drawOperationInfo(
      this.activeScheduleRow,
      areasOperationInfoLayerOff as TextArea[],
      canvasDisplayId,
      ScreenCanvasIdEnum.TIMETABLE_OPERATION_MANAGER
    );

    // draw layer on
    let areasOperationInfoLayerOn = Helper.getAreasOfLayerOnOff(areasOperationInfo, display, this.areaSwitchingTiming, true);
    this.drawTimetableService.drawOperationInfoOn(
      this.activeScheduleRow,
      areasOperationInfoLayerOn as TextArea[],
      canvasDisplayId,
      ScreenCanvasIdEnum.TIMETABLE_OPERATION_MANAGER
    );
  }

  /**
   * calculate time to milliseconds for schedule
   *
   * @param timeConverted
   * @param schedule
   * @param isNewDay
   * @returns
   */
  private calculateTimeToMillisForSchedule(timeConverted: string, schedule: Schedule, isNewDay: boolean) {
    const num = timeConverted.split(':');
    let lengthNum = num.length;
    const seconds = lengthNum === Constant.NUMBER_ELEMENTS_OF_SECOND_FORMAT ? +num[Constant.SECONDS] : 0;
    let hours = +num[Constant.HOURS];
    if (isNewDay) {
      return DateTime.fromFormat('00:00:00', this.FORMAT_HOURS)
        .plus({
          hours: hours < 24 ? 0 : hours % 24,
          minutes: hours < 24 ? 0 : +num[Constant.MINUTES],
          seconds: hours < 24 ? 0 : seconds
        })
        .plus({
          minutes: schedule.operationInfo ? schedule.operationInfo.time : 0
        })
        .toMillis();
    }
    if (hours < 24) {
      if (lengthNum === Constant.NUMBER_ELEMENTS_OF_MINUTE_FORMAT) {
        timeConverted += ':00';
      }
      return DateTime.fromFormat(timeConverted, this.FORMAT_HOURS)
        .plus({
          minutes: schedule.operationInfo ? schedule.operationInfo.time : 0
        })
        .toMillis();
    }
    const hoursPlus = hours % 24;
    return DateTime.fromFormat('24:00:00', this.FORMAT_HOURS)
      .plus({
        hours: hoursPlus,
        minutes: +num[Constant.MINUTES],
        seconds: seconds
      })
      .plus({
        minutes: schedule.operationInfo ? schedule.operationInfo.time : 0
      })
      .toMillis();
  }

  /**
   * check less current time
   */
  private checkLessCurrentTime() {
    this.resetTimerCheckLessCurrentTimeSubject
      .pipe(
        startWith(0),
        switchMap(() => timer(0, 1000)),
        takeUntil(this.pauseTimerCheckLessCurrentTimeSubject)
      )
      .subscribe(async () => {
        const activeScheduleRowClone = _.cloneDeep(this.activeScheduleRow);
        const schedulesSortedByTime = this.getSchedulesSortedByTime();
        this.activeScheduleRow = this.getActiveScheduleRow(schedulesSortedByTime);
        if (_.isEqual(this.activeScheduleRow, activeScheduleRowClone)) {
          return;
        }
        // preview fix area timing on if finish schedule
        this.previewFinishSchedule(this.activeScheduleRow);
        if (!this.isViewMonitorMode) {
          this.drawSchedule();
        }
      });
  }

  /**
   * convert time
   *
   * @param time
   * @returns
   */
  private convertTime(time: string) {
    const num = time.split(':');
    if (num.length === Constant.NUMBER_ELEMENTS_OF_MINUTE_FORMAT) {
      return `${num[Constant.HOURS].padStart(2, '0')}:${num[Constant.MINUTES].padStart(2, '0')}`;
    }
    return `${num[Constant.HOURS].padStart(2, '0')}:${num[Constant.MINUTES].padStart(2, '0')}:${num[Constant.SECONDS].padStart(2, '0')}`;
  }

  /**
   * get schedules sorted by time
   *
   * @returns
   */
  private getSchedulesSortedByTime() {
    if (this.schedules.length == 0) {
      return;
    }
    let isNewDay = Helper.convertHoursToSeconds(this.getCurrentDateByTimeZone(true)) <= this.timeDateLine;
    this.schedules
      .map(schedule => {
        schedule.rowName = undefined;
        return schedule;
      })
      .filter(schedule => !schedule.isLessCurrentTime)
      .forEach(schedule => {
        schedule['millisTime'] = this.calculateTimeToMillisForSchedule(schedule.convertedTime, schedule, isNewDay);
        if (schedule['millisTime'] + this.changeoverOffset * 1000 <= this.getCurrentDateToMillisByTimeZone()) {
          schedule.isLessCurrentTime = true;
        }
      });
    const schedules = this.schedules
      .filter(schedule => !schedule.isLessCurrentTime && (schedule.operationInfo ? schedule.operationInfo.time != null : true))
      .sort((schedule1, schedule2) => schedule1['millisTime'] - schedule2['millisTime']);
    return schedules.splice(this.currentIndexTimetableSchedule, schedules.length);
  }

  /**
   * Get current date to millis by time zone
   *
   * @returns
   */
  private getCurrentDateToMillisByTimeZone(): string {
    var offsetHour = 0;
    var offsetMinute = 0;
    var setting = this.commonObject.setting;
    if (setting) {
      offsetHour = setting.timezone.offsetHour;
      offsetMinute = setting.timezone.offsetMinute;
    }
    const currentDate = moment
      .utc()
      .add(offsetHour, 'hour')
      .add(offsetMinute, 'minute')
      .format('YYYY-MM-DD HH:mm:ss');
    const date = currentDate.split(' ');
    return DateTime.fromFormat(date[1], this.FORMAT_HOURS)
      .plus(0)
      .toMillis();
  }

  /**
   * Get current date by time zone
   *
   * @param isGetHours
   * @returns
   */
  private getCurrentDateByTimeZone(isGetHours: boolean): string {
    var offsetHour = 0;
    var offsetMinute = 0;
    var setting = this.commonObject.setting;
    if (setting) {
      offsetHour = setting.timezone.offsetHour;
      offsetMinute = setting.timezone.offsetMinute;
    }
    return moment
      .utc()
      .add(offsetHour, 'hour')
      .add(offsetMinute, 'minute')
      .format(isGetHours ? this.FORMAT_HOURS : Constant.FORMAT_DATE);
  }

  /**
   * delivery
   */
  public delivery() {
    if (!this.validateActionCommon()) {
      return;
    }
    const checkedDevices = this.devices.filter(device => device.isChecked);
    if (this.isEditDynamicMessageMode) {
      // delivery dynamic message
      if (this.dynamicMessages.length == 0) {
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('dialog-message.error-title'),
            text: this.translateService.instant('dialog-message.timetable-operation.no-dynamic-message')
          }
        });
        return;
      } else if (this.dynamicMessages.some(dynamicMessage => dynamicMessage.message?.length > this.MAX_LENGTH_VALUE)) {
        this.propertiesName.dynamicMessage = this.translateService.instant('dialog-message.timetable-operation.dynamic-message');
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('dialog-message.error-title'),
            text: Helper.getErrorMessage(
              ErrorEnum.MAX_LENGTH,
              this.propertiesName.dynamicMessage,
              this.MAX_LENGTH_VALUE,
              this.translateService
            )
          },
          autoFocus: false
        });
        return;
      }
      // upload file if has image
      if (this.filesData.length) {
        this.mediaService.uploadMediaFromPcToDynamicMessage(this.filesData, this.mediaFilesDroppedOperation).subscribe(
          dataResponse => {
            if (dataResponse) {
              this.handleDataMediaAfterSaved(dataResponse);
              this.saveDataSuccess.emit(true);
            }
            if (this.mediaIdsToDelete.length) {
              this.mediaService.deleteMediasDynamicMessage(this.mediaIdsToDelete).subscribe(
                () => {
                  this.clearDataMediaDropToDynamicMessages();
                  this.handleDeliveryDynamicMessage(checkedDevices);
                },
                error => Helper.handleError(error, this.translateService, this.dialogService)
              );
            } else {
              this.clearDataMediaDropToDynamicMessages();
              this.handleDeliveryDynamicMessage(checkedDevices);
            }
          },
          error => {
            Helper.handleError(error, this.translateService, this.dialogService);
            this.saveDataSuccess.emit(false);
            return;
          }
        );
      } else {
        this.handleDeliveryDynamicMessage(checkedDevices);
      }
    } else if (this.isEditEmergencyMode) {
      // delivery emergency mode
      if (this.emergencyData.emergencyText?.length > this.MAX_LENGTH_VALUE) {
        this.propertiesName.emergencyLinkText = this.translateService.instant('dialog-message.timetable-operation.link-text');
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('dialog-message.error-title'),
            text: Helper.getErrorMessage(
              ErrorEnum.MAX_LENGTH,
              this.propertiesName.emergencyLinkText,
              this.MAX_LENGTH_VALUE,
              this.translateService
            )
          }
        });
        return;
      }
      this.updateEmergencyModeForDevices(checkedDevices, true);
    } else {
      // delivery schedule
      if (!this.deviceSelected || this.schedules.length == 0) {
        return;
      }
      let operationInfos = this.schedules
        .filter(schedule => schedule.operationInfo && !schedule.isLessCurrentTime)
        .map(schedule => {
          const index = this.schedules.findIndex(sch => sch == schedule);
          return {
            index: index != null ? index + 1 : null,
            delay: schedule.operationInfo ? schedule.operationInfo.time : 0,
            cancelled: false,
            information: schedule.operationInfo ? Helper.encodeHTML(schedule.operationInfo.text) : null
          };
        });
      const payloadDelayInfo = {
        device: this.deviceSelected.registrationId,
        operationInfo: operationInfos
      };
      this.apiCustomerService.changeTimetableDelayInfo(payloadDelayInfo).subscribe(
        () => {
          this.executingService.executing();
          const intervalCallAPI = interval(this.NUMBER_MILLISECONDS_CALL_API);
          const takeNumbers = intervalCallAPI.pipe(take(this.NUMBERS_OF_API_CALLS));
          const sub = takeNumbers
            .pipe(
              concatMap(num => {
                return this.handleDataDeliveryOperationInfo(payloadDelayInfo, num, sub);
              })
            )
            .subscribe(() => {});
        },
        error => this.handleErrorFromCustomerApi('delivery-failed', error)
      );
    }
  }

  /**
   * handle delivery dynamic message
   * @param checkedDevices
   */
  private handleDeliveryDynamicMessage(checkedDevices: Device[]): void {
    let mediasDynamic = this.dynamicMessages.filter(item => !item[this.URL_MEDIA_DROP_PC] && item.media)?.map(dynamic => dynamic.media);
    if (mediasDynamic?.length) {
      const mediaIds = mediasDynamic.map(media => media.id);
      this.mediaService.getMediaUrlsDynamicMessage(mediaIds).subscribe(mediaUrls => {
        // update url to delivery for dynamic message
        if (mediaUrls?.length) {
          mediaIds.forEach((mediaId, index) => {
            const indexDynamic = this.dynamicMessages.findIndex(data => data.media?.id == mediaId);
            if (indexDynamic != -1) {
              this.dynamicMessages[indexDynamic][this.URL_MEDIA_DROP_PC] = mediaUrls[index];
            }
          });
        }
        this.handleCallAPIDynamicMessage(checkedDevices);
      });
    } else {
      this.handleCallAPIDynamicMessage(checkedDevices);
    }
  }

  /**
   * handle call api dynamic message
   * @param checkedDevices
   */
  private handleCallAPIDynamicMessage(checkedDevices: Device[]): void {
    const dynamicMessageList = checkedDevices.map(device => {
      return {
        device: device.registrationId,
        dynamic_message: this.dynamicMessages
          .filter(dynamicMessage => dynamicMessage.device.id == device.id)
          .map(dynamicMessage => {
            if (dynamicMessage.textArea) {
              return {
                area: `${Constant.PREFIX_AREA}${dynamicMessage.textArea.id}`,
                type: Constant.TYPE_DYNAMIC_TEXT,
                message: Helper.encodeHTML(dynamicMessage.message)
              };
            } else if (dynamicMessage.pictureArea) {
              return {
                area: `${Constant.PREFIX_AREA}${dynamicMessage.pictureArea.id}`,
                type: Constant.TYPE_DYNAMIC_IMAGE,
                image: dynamicMessage[this.URL_MEDIA_DROP_PC] ?? Constant.EMPTY
              };
            }
          })
      };
    });
    const payload = {
      dynamic_message_list: dynamicMessageList
    };
    this.apiCustomerService.deliveryDynamicMessage(payload).subscribe(
      async () => {
        this.executingService.executing();
        const checkedDevicesSave = await this.getDevicesSuccessForDelivery(checkedDevices, true, payload);
        if (checkedDevicesSave.length == 0) {
          this.handleErrorFromCustomerApi('delivery-message-failed');
          return;
        }
        this.saveDynamicMessages(this.dynamicMessages, checkedDevices, checkedDevicesSave, true);
        this.toast.success(this.translateService.instant('timetable-operation-manager.delivery-success'), '');
      },
      error => this.handleErrorFromCustomerApi('delivery-message-failed', error)
    );
  }

  /**
   * handle data media after saved
   * @param mediaFiles
   */
  private handleDataMediaAfterSaved(mediaFiles: MediaFileDropped[]): void {
    mediaFiles.forEach((file, index) => {
      const indexDynamic = this.dynamicMessages.findIndex(data => data.uuidV4Image == file.uuidV4);
      if (indexDynamic == -1) {
        return;
      }
      this.dynamicMessages[indexDynamic].media = Helper.convertMediaData(mediaFiles[index].media);
      this.dynamicMessages[indexDynamic][this.URL_MEDIA_DROP_PC] = mediaFiles[index].urlMediaDelivery;
    });
  }

  /**
   * Check valid data operation information
   *
   * @param payloadDelayInfo
   * @param realtimeData
   * @returns
   */
  private checkValidDataOperationInfo(payloadDelayInfo: any, realtimeData: any): boolean {
    const responseDelayInfo = [...realtimeData[this.DELAY_INFORMATION]];
    const currentIndexNumber = [...realtimeData['currentIndexNumber']];
    if (responseDelayInfo.every(data => !data) && [...payloadDelayInfo].length == 0) {
      return true;
    }
    if (currentIndexNumber.length == 0 && [...payloadDelayInfo].length > 0) {
      return false;
    }
    let delayInfos = [];
    currentIndexNumber.forEach(currentIndex => {
      delayInfos.push(
        currentIndex ? Helper.encodeHTML(this.schedules.find((data, index) => index + 1 == currentIndex)?.operationInfo?.text) ?? '' : null
      );
    });
    return JSON.stringify(delayInfos) == JSON.stringify(responseDelayInfo);
  }

  /**
   * Handle data delivery operation information
   *
   * @param payloadDelayInfo
   * @param count
   * @param sub
   * @returns
   */
  private async handleDataDeliveryOperationInfo(payloadDelayInfo: any, count: number, sub: any): Promise<void> {
    const payloadRealtime = {
      device: this.deviceSelected.registrationId
    };
    return new Promise<void>(resolve => {
      this.apiCustomerService
        .getTimetableRealtimeStatus(payloadRealtime)
        .pipe(takeUntil(this.subject))
        .subscribe(
          realtimeData => {
            if (realtimeData && this.checkValidDataOperationInfo(payloadDelayInfo['operationInfo'], realtimeData)) {
              sub.unsubscribe();
              if (this.deviceSelected.isManuallySetOperatingSystem) {
                this.changeTimetableDisplayIndex();
              } else {
                this.toast.success(this.translateService.instant('timetable-operation-manager.delivery-success'), '');
              }
              this.schedules.forEach(schedule => {
                schedule.isChangedDelayInfo = false;
              });
              this.oldOperationInfos = _.cloneDeep(this.operationInfoOptions);
              // save operation info
              const payload = {
                id: this.scheduleOperationInfo?.id,
                operationInfo: JSON.stringify(
                  this.schedules.map(schedule => {
                    return schedule.operationInfo ? schedule.operationInfo.id : null;
                  })
                ),
                timetableContentDayId: this.scheduleOperationInfo?.timetableContentDayId
              };
              this.scheduleOperationInfoService.saveOperationInfo(payload).subscribe(
                scheduleOperationInfoData => {
                  this.executingService.executed();
                  this.scheduleOperationInfo = this.convertDataScheduleOperationInfo(scheduleOperationInfoData);
                },
                () => this.handleErrorFromApi()
              );
            } else if (count == this.NUMBERS_OF_API_CALLS - 1) {
              this.subject.next();
              this.executingService.executed();
              this.handleErrorFromCustomerApi('delivery-failed');
            }
            resolve();
          },
          () => {
            if (count == this.NUMBERS_OF_API_CALLS - 1) {
              this.executingService.executed();
              this.handleErrorFromCustomerApi('call-realtime-failed');
              this.subject.next();
            } else {
              this.executingService.executing();
              resolve();
            }
          }
        );
    });
  }

  /**
   * Get devices success for delivery
   *
   * @param devices
   * @param isDynamicMessage
   * @param payloadData
   * @returns
   */
  private async getDevicesSuccessForDelivery(devices: Array<any>, isDynamicMessage: boolean, payloadData?: any): Promise<any> {
    let checkedDevicesSave = [];
    if (!devices) {
      return checkedDevicesSave;
    }
    await Promise.all(
      devices.map(async checkedDevice => {
        const payloadRealtime = {
          device: isDynamicMessage ? checkedDevice.registrationId : checkedDevice
        };
        let dataPayload;
        if (payloadData) {
          dataPayload = isDynamicMessage
            ? [...payloadData['dynamic_message_list']].find(data => data['device'] == payloadRealtime.device)['dynamic_message']
            : payloadData;
        }
        try {
          await this.handleCallApiCustomer(payloadRealtime, checkedDevice, dataPayload).then(data => {
            if (data) {
              checkedDevicesSave.push(data);
            }
          });
        } catch (error) {
          //@ts-ignore
        }
      })
    );
    return checkedDevicesSave;
  }

  /**
   * async interval
   * @param payloadRealtime
   * @param checkedDeviceSave
   * @param payloadData
   * @param triesCallAPI
   * @returns
   */
  async asyncInterval(payloadRealtime, checkedDeviceSave, payloadData?: any, triesCallAPI = this.NUMBERS_OF_API_CALLS) {
    return new Promise((resolve, reject) => {
      let checkNum = this.getDataNeedCheck(payloadData);
      const interval = setInterval(async () => {
        this.apiCustomerService.getTimetableRealtimeStatus(payloadRealtime).subscribe(
          realtimeData => {
            switch (checkNum) {
              case APIEnum.OFF_EMERGENCY:
                if (
                  realtimeData &&
                  !realtimeData[this.EMERGENCY_MESSAGE][this.IMAGE_ATTRIBUTE] &&
                  !realtimeData[this.EMERGENCY_MESSAGE][this.MESSAGE_ATTRIBUTE]
                ) {
                  resolve(checkedDeviceSave);
                  clearInterval(interval);
                }
                break;
              case APIEnum.DYNAMIC_MESSAGE:
                if (realtimeData && _.isEqual(realtimeData[this.DYNAMIC_MESSAGE], payloadData)) {
                  resolve(checkedDeviceSave);
                  clearInterval(interval);
                }
                break;
              case APIEnum.ON_EMERGENCY:
                if (
                  realtimeData &&
                  realtimeData[this.EMERGENCY_MESSAGE][this.IMAGE_ATTRIBUTE] == payloadData[this.IMAGE_ATTRIBUTE] &&
                  realtimeData[this.EMERGENCY_MESSAGE][this.MESSAGE_ATTRIBUTE] == payloadData[this.MESSAGE_ATTRIBUTE]
                ) {
                  resolve(checkedDeviceSave);
                  clearInterval(interval);
                }
                break;
              default:
                break;
            }
            if (triesCallAPI <= 1) {
              reject();
              resolve(undefined);
              clearInterval(interval);
              this.executingService.executed();
            }
            triesCallAPI--;
          },
          () => {
            // call api error
            triesCallAPI--;
            if (triesCallAPI <= 0) {
              reject();
              resolve(undefined);
              clearInterval(interval);
              this.executingService.executed();
            } else {
              this.executingService.executing();
            }
          }
        );
      }, this.NUMBER_MILLISECONDS_CALL_API);
    });
  }

  /**
   * Get data need check
   *
   * @param payloadData
   * @returns APINeedCheckEnum
   */
  private getDataNeedCheck(payloadData: any): APIEnum {
    if (!payloadData) {
      return APIEnum.OFF_EMERGENCY;
    }
    if (Array.isArray(payloadData)) {
      return APIEnum.DYNAMIC_MESSAGE;
    }
    return APIEnum.ON_EMERGENCY;
  }

  /**
   * handle call api customer
   * @param payloadRealtime
   * @param checkedDeviceSave
   * @param payloadData
   * @returns
   */
  async handleCallApiCustomer(payloadRealtime, checkedDeviceSave, payloadData?: any) {
    try {
      return await this.asyncInterval(payloadRealtime, checkedDeviceSave, payloadData);
    } catch (error) {
      //@ts-ignore
    }
  }

  /**
   * Save dynamic messages
   *
   * @param dynamicMessages
   * @param checkedDevices
   * @param checkedDevicesSave
   * @param isNotLeave
   */
  private saveDynamicMessages(
    dynamicMessages: Array<DynamicMessage>,
    checkedDevices: Device[],
    checkedDevicesSave?: Device[],
    isNotLeave?: boolean
  ): void {
    // upload file if has image
    if (this.filesData.length) {
      this.mediaService.uploadMediaFromPcToDynamicMessage(this.filesData, this.mediaFilesDroppedOperation).subscribe(
        dataResponse => {
          if (dataResponse) {
            this.handleDataMediaAfterSaved(dataResponse);
          }
          if (this.mediaIdsToDelete.length) {
            this.mediaService.deleteMediasDynamicMessage(this.mediaIdsToDelete).subscribe(
              () => {
                this.clearDataMediaDropToDynamicMessages();
                this.handleSaveDynamicMessage(dynamicMessages, checkedDevices, checkedDevicesSave, isNotLeave);
              },
              error => Helper.handleError(error, this.translateService, this.dialogService)
            );
          } else {
            this.clearDataMediaDropToDynamicMessages();
            this.handleSaveDynamicMessage(dynamicMessages, checkedDevices, checkedDevicesSave, isNotLeave);
          }
        },
        error => {
          Helper.handleError(error, this.translateService, this.dialogService);
          this.saveDataSuccess.emit(false);
          return;
        }
      );
    } else {
      this.handleSaveDynamicMessage(dynamicMessages, checkedDevices, checkedDevicesSave, isNotLeave);
    }
  }

  /**
   * handle save dynamic messages
   *
   * @param dynamicMessages
   * @param checkedDevices
   * @param checkedDevicesSave
   * @param isNotLeave
   */
  private handleSaveDynamicMessage(
    dynamicMessages: DynamicMessage[],
    checkedDevices: Device[],
    checkedDevicesSave: Device[],
    isNotLeave?: boolean
  ): void {
    const currentDate = this.getCurrentDateByConditions();
    let dynamicMessagesSave = dynamicMessages.map(dynamicMessage => {
      return {
        id: dynamicMessage.id,
        textArea: dynamicMessage.textArea,
        pictureArea: dynamicMessage.pictureArea,
        deviceId: dynamicMessage.device.id,
        message: dynamicMessage.message,
        mediaId: dynamicMessage.media?.id
      };
    });
    if (checkedDevicesSave) {
      const checkedDevicesId = checkedDevicesSave.map(device => {
        return device.id;
      });
      dynamicMessagesSave = dynamicMessagesSave.filter(dynamicMessage => checkedDevicesId.find(id => +id == dynamicMessage.deviceId));
    }
    this.dynamicMessageService.saveDynamicMessages(dynamicMessagesSave).subscribe(
      () => {
        if (!isNotLeave) {
          this.saveDataSuccess.emit(true);
          return;
        }
        this.dynamicMessageService
          .getDynamicMessageForMultiDeviceByDeviceId(
            checkedDevices.map(device => device.id),
            currentDate
          )
          .subscribe(
            data => {
              this.executingService.executed();
              this.dynamicMessages = this.handleDataDynamicMessage(data);
              this.dataService.sendData([
                `${MODULE_NAME[FIELD_COMPONENT.TimetableOperationManagerComponent]}:isSelectDynamicMessage`,
                false
              ]);
              this.oldDynamicMessages = _.cloneDeep(this.dynamicMessages);
            },
            () => {
              this.handleErrorFromApi();
            }
          );
        this.saveDataSuccess.emit(true);
      },
      () => {
        this.saveDataSuccess.emit(false);
        this.handleErrorFromApi();
      }
    );
  }

  /**
   *Handle data dynamic message
   *
   * @param dynamicMessagesData
   */
  private handleDataDynamicMessage(dynamicMessagesData: DynamicMessage[]): DynamicMessage[] {
    return dynamicMessagesData
      .map(dynamicMessageData => {
        return this.convertDataDynamicMessageFromServer(dynamicMessageData);
      })
      .sort(
        (dv1, dv2) =>
          +dv1.device.id - +dv2.device.id || +dv1.textArea?.id - +dv2.textArea?.id || +dv1.pictureArea?.id - +dv2.pictureArea?.id
      );
  }

  /**
   * handle error from customer api
   *
   * @param errorApi
   * @param error
   */
  private handleErrorFromCustomerApi(errorApi: string, error?: any) {
    let msg = this.translateService.instant(`dialog-message.timetable-operation.${errorApi}`);
    if (error && error.status == Constant.NETWORK_ERROR_CODE) {
      msg = this.translateService.instant('dialog-error.error-network-api');
    }
    this.dialogService.showDialog(DialogMessageComponent, {
      data: {
        title: this.translateService.instant('dialog-message.error-title'),
        text: msg
      }
    });
  }

  /**
   * change timetable display index
   *
   * @returns
   */
  private changeTimetableDisplayIndex() {
    let currentIndexNumber = {};
    let scheduleRowIndex = {};
    for (let index = 0; index < this.MAX_CURRENT_INDEX; index++) {
      const data = this.schedules.findIndex(schedule => schedule == this.activeScheduleRow[`${this.CURRENT}_${index}`]);
      currentIndexNumber[`${this.INDEX_CURRENT}_${index}`] = data != -1 ? data + 1 : null;
      scheduleRowIndex[`${this.INDEX_CURRENT}_${index}`] = data != -1 ? data : null;
    }
    const payload = {
      device: this.deviceSelected.registrationId,
      current_index_number: Object.values(currentIndexNumber)
    };
    this.apiCustomerService.changeTimetableDisplayIndex(payload).subscribe(
      () => {
        this.executingService.executing();
        const payloadRealtime = {
          device: this.deviceSelected.registrationId
        };
        const intervalCallAPI = interval(this.NUMBER_MILLISECONDS_CALL_API);
        const takeNumbers = intervalCallAPI.pipe(take(this.NUMBERS_OF_API_CALLS));
        const sub = takeNumbers
          .pipe(
            concatMap(num => {
              return this.handleDataDeliveryDisplayIndex(sub, payloadRealtime, payload, scheduleRowIndex, num);
            })
          )
          .subscribe(() => {});
      },
      error => this.handleErrorFromCustomerApi('delivery-failed', error)
    );
  }

  /**
   * Handle data delivery display index
   *
   * @param sub
   * @param payloadRealtime
   * @param payload
   * @param scheduleRowIndex
   * @param count
   * @returns
   */
  private handleDataDeliveryDisplayIndex(
    sub: any,
    payloadRealtime: any,
    payload: any,
    scheduleRowIndex: any,
    count: number
  ): Promise<void> {
    return new Promise<void>(resolve => {
      this.apiCustomerService
        .getTimetableRealtimeStatus(payloadRealtime)
        .pipe(takeUntil(this.subject))
        .subscribe(
          realtimeData => {
            if (realtimeData && JSON.stringify(realtimeData['currentIndexNumber']) == JSON.stringify(payload['current_index_number'])) {
              sub.unsubscribe();
              this.executingService.executed();
              this.scheduleRowIndex = scheduleRowIndex as any;
              this.schedules.forEach(schedule => {
                schedule.isChangedIndex = false;
              });
              this.scheduleDisplayIndex.displayIndex = _.cloneDeep(this.scheduleRowIndex);
              this.scheduleDisplayIndexService
                .saveScheduleDisplayIndex(Helper.convertDataScheduleDisplayIndexBackward(this.scheduleDisplayIndex))
                .toPromise();
              this.oldScheduleRowIndex = _.cloneDeep(this.scheduleRowIndex);
              this.toast.success(this.translateService.instant('timetable-operation-manager.delivery-success'), '');
            } else if (count == this.NUMBERS_OF_API_CALLS - 1) {
              this.executingService.executed();
              this.handleErrorFromCustomerApi('delivery-failed');
              this.subject.next();
            }
            resolve();
          },
          () => {
            if (count == this.NUMBERS_OF_API_CALLS - 1) {
              this.executingService.executed();
              this.handleErrorFromCustomerApi('call-realtime-failed');
              this.subject.next();
            } else {
              this.executingService.executing();
              resolve();
            }
          }
        );
    });
  }

  /**
   * cancel
   */
  public cancel() {
    if (!this.validateActionCommon()) {
      return;
    }
    if (this.isEditDynamicMessageMode) {
      this.dynamicMessages = [];
      const currentDate = this.getCurrentDateByConditions();
      this.dynamicMessageService.getDynamicMessageForMultiDeviceByDeviceId([this.deviceSelected.id], currentDate).subscribe(
        dynamicMessageData => {
          this.dynamicMessages = dynamicMessageData.map(dynamicMessageData => {
            return this.convertDataDynamicMessageFromServer(dynamicMessageData);
          });
          this.clearDataMediaDropToDynamicMessages();
          if (this.dynamicMessages?.length) {
            this.reDrawDynamicMessage(this.dynamicMessages);
          }
          this.isEditDynamicMessageMode = false;
          this.dataService.sendData([`${MODULE_NAME[FIELD_COMPONENT.TimetableOperationManagerComponent]}:isSelectDynamicMessage`, false]);
          this.updateStateItemOnMenu.next();
        },
        error => this.handleErrorFromCustomerApi('an-error', error)
      );
    } else if (this.isEditEmergencyMode) {
      this.emergencyDataService.getEmergencyData().subscribe(
        data => {
          if (data.length == 0) {
            this.emergencyData = new EmergencyData(Constant.EMPTY, -1, Constant.EMPTY);
            this.drawTimetableService.setDataPreviewTimetableOperationManager(null, null, null, this.emergencyData);
            this.emergencyMediaIdOld = this.emergencyData.emergencyImageId;
            this.clearAreasEmergency();
            this.isEditEmergencyMode = false;
            this.drawTimetableService.setDataIsOnEmergency(this.deviceSelected.isOnEmergency || this.isEditEmergencyMode);
            this.updateStateItemOnMenu.next();
            return;
          }
          this.emergencyData = data[0];
          if (this.emergencyData.emergencyImageId == -1 || !this.emergencyData.emergencyImageId || !this.emergencyData.media) {
            this.emergencyData.media = null;
            this.emergencyData.urlImage = Constant.EMPTY;
          }
          this.emergencyMediaIdOld = this.emergencyData.emergencyImageId;
          this.drawTimetableService.setDataPreviewTimetableOperationManager(null, null, null, this.emergencyData);
          if (this.deviceSelected.isOnEmergency) {
            this.reDrawEmergency(this.emergencyData);
          } else {
            this.clearAreasEmergency();
          }
          this.isEditEmergencyMode = false;
          this.drawTimetableService.setDataIsOnEmergency(this.deviceSelected.isOnEmergency || this.isEditEmergencyMode);
          this.updateStateItemOnMenu.next();
        },
        error => this.handleErrorFromCustomerApi('an-error', error)
      );
    } else {
      if (!this.deviceSelected || this.schedules.length == 0) {
        return;
      }
      this.schedules.forEach((schedule, index) => {
        schedule.operationInfo = this.operationInfoOptions.find(
          operationInfo => operationInfo.id == this.scheduleOperationInfo.operationInfo[index]
        );
        schedule.isChangedDelayInfo = false;
        schedule.isChangedIndex = false;
      });
      this.oldOperationInfos = _.cloneDeep(this.operationInfoOptions);
      if (this.deviceSelected.isManuallySetOperatingSystem) {
        this.schedules.forEach(schedule => (schedule.rowName = undefined));
        for (let index = 0; index < this.MAX_CURRENT_INDEX; index++) {
          this.activeScheduleRow[`${this.CURRENT}_${index}`] = this.schedules[this.scheduleRowIndex[`${this.INDEX_CURRENT}_${index}`]];
          if (this.activeScheduleRow[`${this.CURRENT}_${index}`]) {
            this.activeScheduleRow[`${this.CURRENT}_${index}`].rowName = this.rowName[index].name;
          }
        }
      }
      this.drawTimetableService.changeStartState(true, ScreenCanvasIdEnum.TIMETABLE_OPERATION_MANAGER);
      this.drawSchedule();
    }
  }

  /**
   * Clear areas emergency
   */
  private clearAreasEmergency(): void {
    const areasDisplay1 = this.getAllAreasEmergencyMessage(DisplaysEnum.DISPLAY_1);
    this.drawTimetableService.clearAreas(areasDisplay1, this.canvasDisplay1Id, ScreenCanvasIdEnum.TIMETABLE_OPERATION_MANAGER);
    if (this.isDisplay2) {
      const areasDisplay2 = this.getAllAreasEmergencyMessage(DisplaysEnum.DISPLAY_2);
      this.drawTimetableService.clearAreas(areasDisplay2, this.canvasDisplay2Id, ScreenCanvasIdEnum.TIMETABLE_OPERATION_MANAGER);
    }
    this.drawTimetableService.changeStartState(true, ScreenCanvasIdEnum.TIMETABLE_OPERATION_MANAGER);
    this.drawTimetableService.clearTimeoutsStopDurationArea(false, ScreenCanvasIdEnum.TIMETABLE_OPERATION_MANAGER, [
      LinkDataTextEnum.EMERGENCY_MESSAGE
    ]);
  }

  /**
   * change display
   */
  private changeDisplay() {
    this.isDisplay2 = !this.isDisplay2;
    this.commonObject.isDisplay2TimetableOperation = this.isDisplay2;
    this.store.dispatch(
      new SaveMainStateAction({
        common: this.commonObject
      })
    );
    if (this.isViewMonitorMode) {
      return;
    }
    this.panzoomDisplay1 = undefined;
    this.panzoomDisplay2 = undefined;
    this.monitorResponseData = undefined;
    this.drawTimetableService.clearAllThreadDrawTemplate(
      _.get(this.timetableSelected?.templateDisplay1s, `[${this.templateSelectedTypeDisplay1}]`, undefined),
      this.canvasDisplay1Id,
      ScreenCanvasIdEnum.TIMETABLE_OPERATION_MANAGER
    );
    this.drawTimetableService.clearAllThreadDrawTemplate(
      _.get(this.timetableSelected?.templateDisplay2s, `[${this.templateSelectedTypeDisplay2}]`, undefined),
      this.canvasDisplay2Id,
      ScreenCanvasIdEnum.TIMETABLE_OPERATION_MANAGER
    );
    // clear thread draw external content
    this.drawTimetableService.clearAllIntervalDrawsNewsDisplay1();
    this.drawTimetableService.clearAllIntervalDrawsNewsDisplay2();
    this.clearTimeoutDisplay(this.timeoutsDisplay1);
    this.clearTimeoutDisplay(this.timeoutsDisplay2);
    this.subscribesGetDataExternal.forEach(subscription => subscription?.unsubscribe());
    this.pausePreview();
    this.drawTimetableService.changeStartState(true, ScreenCanvasIdEnum.TIMETABLE_OPERATION_MANAGER);
    this.drawPreviewModePreview();
  }

  /**
   * Select Page
   *
   * @param templateType
   * @param displayEnum
   */

  public selectPage(templateType: DestinationEnum, displayEnum: DisplaysEnum) {
    if (this.isPlayPreview) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-message.error-title'),
          text: Helper.getErrorMessage(ErrorEnum.PREVIEW_IS_PLAYING, null, null, this.translateService)
        }
      });
      return;
    }
    // assign template type
    if (displayEnum == DisplaysEnum.DISPLAY_1) {
      this.templateOld = _.get(this.timetableSelected?.templateDisplay1s, `[${this.templateSelectedTypeDisplay1}]`, undefined);
      this.templateSelectedTypeDisplay1 = templateType;
    } else {
      this.templateOld = _.get(this.timetableSelected?.templateDisplay2s, `[${this.templateSelectedTypeDisplay2}]`, undefined);
      this.templateSelectedTypeDisplay2 = templateType;
    }
    // draw mode preview
    if (!this.isViewMonitorMode) {
      this.monitorResponseData = undefined;
      if (displayEnum == DisplaysEnum.DISPLAY_1) {
        this.drawTimetableService.changeStatePlayPause(
          this.isPlayPreview,
          this.canvasDisplay1Id,
          ScreenCanvasIdEnum.TIMETABLE_OPERATION_MANAGER
        );
        this.drawDisplay(
          _.get(this.timetableSelected?.templateDisplay1s, `[${this.templateSelectedTypeDisplay1}]`, undefined),
          this.divContainCanvas1,
          this.divPreviewDisplay1,
          DisplaysEnum.DISPLAY_1,
          this.canvasDisplay1Id
        );
      } else {
        this.drawTimetableService.changeStatePlayPause(
          this.isPlayPreview,
          this.canvasDisplay2Id,
          ScreenCanvasIdEnum.TIMETABLE_OPERATION_MANAGER
        );
        this.drawDisplay(
          _.get(this.timetableSelected?.templateDisplay2s, `[${this.templateSelectedTypeDisplay2}]`, undefined),
          this.divContainCanvas2,
          this.divPreviewDisplay2,
          DisplaysEnum.DISPLAY_2,
          this.canvasDisplay2Id
        );
      }
      // draw mode monitor
    }
  }

  /**
   * view preview mode
   */
  public viewPreviewMode() {
    if (this.isPlayPreview) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-message.error-title'),
          text: Helper.getErrorMessage(ErrorEnum.PREVIEW_IS_PLAYING, null, null, this.translateService)
        }
      });
      return;
    }
    this.isViewMonitorMode = false;
    this.dataService.sendData([`${MODULE_NAME[FIELD_COMPONENT.TimetableOperationManagerComponent]}:isViewMonitorMode`, false]);
    this.monitorResponseData = undefined;
    this.commonObject.isViewMonitorMode = this.isViewMonitorMode;
    this.store.dispatch(
      new SaveMainStateAction({
        common: this.commonObject
      })
    );
    this.updateStateItemOnMenu.next();
    this.clearAllDrawThread();
    this.timetableSelected = _.cloneDeep(this.timetableModePreview);
    this.templateSelectedTypeDisplay1 = _.cloneDeep(this.typeDisplay1ModePreview);
    this.templateSelectedTypeDisplay2 = _.cloneDeep(this.typeDisplay2ModePreview);
    this.buttonsPreviewDisplay1 = this.getButtonsPreview(this.timetableSelected?.displayTemplate1);
    this.buttonsPreviewDisplay2 = this.getButtonsPreview(this.timetableSelected?.displayTemplate2);
    this.drawTimetableService.pausePreview(this.canvasDisplay1Id, ScreenCanvasIdEnum.TIMETABLE_OPERATION_MANAGER);
    if (this.isDisplay2) {
      this.drawTimetableService.pausePreview(this.canvasDisplay2Id, ScreenCanvasIdEnum.TIMETABLE_OPERATION_MANAGER);
    }
    this.drawTimetableService.clearTimeoutsStopDurationArea(true, ScreenCanvasIdEnum.TIMETABLE_OPERATION_MANAGER);
    if (
      !this.deviceSelected ||
      (!_.get(this.timetableSelected?.templateDisplay1s, `[${this.templateSelectedTypeDisplay1}]`, undefined) &&
        !_.get(this.timetableSelected?.templateDisplay2s, `[${this.templateSelectedTypeDisplay2}]`, undefined))
    ) {
      Helper.clearNodeChild(this.divContainCanvas1?.nativeElement);
      return;
    }
    this.drawTimetableService.changeStartState(true, ScreenCanvasIdEnum.TIMETABLE_OPERATION_MANAGER);
    setTimeout(() => {
      this.drawPreviewModePreview();
    }, 50);
  }

  /**
   * Clear all draw thread
   */
  private clearAllDrawThread(): void {
    if (this.timetableSelected?.templateDisplay1s) {
      this.drawTimetableService.clearAllThreadDrawTemplate(
        _.get(this.timetableSelected?.templateDisplay1s, `[${this.templateSelectedTypeDisplay1}]`, undefined),
        this.canvasDisplay1Id,
        ScreenCanvasIdEnum.TIMETABLE_OPERATION_MANAGER
      );
    }
    if (this.timetableSelected?.templateDisplay2s) {
      this.drawTimetableService.clearAllThreadDrawTemplate(
        _.get(this.timetableSelected?.templateDisplay2s, `[${this.templateSelectedTypeDisplay2}]`, undefined),
        this.canvasDisplay2Id,
        ScreenCanvasIdEnum.TIMETABLE_OPERATION_MANAGER
      );
    }
  }

  /**
   * view monitor mode
   */
  public viewMonitorMode() {
    if (this.isPlayPreview) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-message.error-title'),
          text: Helper.getErrorMessage(ErrorEnum.PREVIEW_IS_PLAYING, null, null, this.translateService)
        }
      });
      return;
    }
    this.isViewMonitorMode = true;
    this.dataService.sendData([`${MODULE_NAME[FIELD_COMPONENT.TimetableOperationManagerComponent]}:isViewMonitorMode`, true]);
    this.commonObject.isViewMonitorMode = this.isViewMonitorMode;
    this.store.dispatch(
      new SaveMainStateAction({
        common: this.commonObject
      })
    );
    this.updateStateItemOnMenu.next();
    this.drawTimetableService.pausePreview(this.canvasDisplay1Id, ScreenCanvasIdEnum.TIMETABLE_OPERATION_MANAGER);
    if (this.isDisplay2) {
      this.drawTimetableService.pausePreview(this.canvasDisplay2Id, ScreenCanvasIdEnum.TIMETABLE_OPERATION_MANAGER);
    }
    this.clearAllDrawThread();
    this.checkNetWorkAndGetDataPreviewModeMonitor();
  }

  /**
   * reset preview
   */
  public resetPreview() {
    if (this.isViewMonitorMode || this.currentIndexTimetableSchedule == 0 || this.deviceSelected.isManuallySetOperatingSystem) {
      return;
    }
    this.isPlayPreview = false;
    this.dataService.sendData([`${MODULE_NAME[FIELD_COMPONENT.TimetableOperationManagerComponent]}:isPlayPreview`, false]);
    this.pauseTimerCheckLessCurrentTimeSubject.next();
    this.pausePreviewSubject.next();
    this.updateStateItemOnMenu.next();
    this.clearTimeoutDisplay(this.timeoutsDisplay1);
    this.clearTimeoutDisplay(this.timeoutsDisplay2);
    this.currentIndexTimetableSchedule = 0;
    this.activeScheduleRow = this.getActiveScheduleRow(this.getSchedulesSortedByTime());
    this.drawTimetableService.clearTimeoutsStopDurationArea(false, ScreenCanvasIdEnum.TIMETABLE_OPERATION_MANAGER, [
      LinkDataTextEnum.INDEX_WORD,
      LinkDataTextEnum.TIMETABLE,
      LinkDataTextEnum.OPERATION_INFO
    ]);
    this.drawTimetableService.changeStartState(true, ScreenCanvasIdEnum.TIMETABLE_OPERATION_MANAGER);
    this.drawPreviewModePreview();
    this.checkLessCurrentTime();
  }

  /**
   * prev preview
   */
  public prevPreview() {
    if (
      !this.isPlayPreview ||
      this.isViewMonitorMode ||
      this.currentIndexTimetableSchedule == 0 ||
      Object.values(this.activeScheduleRow).every(item => !item) ||
      this.deviceSelected.isManuallySetOperatingSystem
    ) {
      return;
    }
    this.pauseTimerCheckLessCurrentTimeSubject.next();
    this.currentIndexTimetableSchedule--;
    this.activeScheduleRow = this.getActiveScheduleRow(this.getSchedulesSortedByTime());
    this.drawSchedule();
    this.checkLessCurrentTime();
  }

  /**
   * play preview
   */
  public playPreview() {
    if (this.isEditEmergencyMode && this.emergencyData.emergencyText?.length > this.MAX_LENGTH_VALUE) {
      this.propertiesName.emergencyLinkText = this.translateService.instant('dialog-message.timetable-operation.link-text');
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-message.error-title'),
          text: Helper.getErrorMessage(
            ErrorEnum.MAX_LENGTH,
            this.propertiesName.emergencyLinkText,
            this.MAX_LENGTH_VALUE,
            this.translateService
          )
        }
      });
      return;
    }
    this.isPlayPreview = true;
    this.dataService.sendData([`${MODULE_NAME[FIELD_COMPONENT.TimetableOperationManagerComponent]}:isPlayPreview`, true]);
    if (!this.deviceSelected || !this.timetableSelected) {
      return;
    }
    this.startPreviewSubject.next();
    this.updateStateItemOnMenu.next();
    this.changeDetectorRef.detectChanges();
    // draw auto transaction
    this.drawDisplayDestinationIndex(
      _.get(this.timetableSelected.templateDisplay1s, `[${this.templateSelectedTypeDisplay1}]`, undefined),
      this.canvasDisplay1Id
    );
    if (this.isDisplay2) {
      this.drawDisplayDestinationIndex(
        _.get(this.timetableSelected.templateDisplay2s, `[${this.templateSelectedTypeDisplay2}]`, undefined),
        this.canvasDisplay2Id
      );
    }
    // play
    this.drawTimetableService.playPreview(this.canvasDisplay1Id, ScreenCanvasIdEnum.TIMETABLE_OPERATION_MANAGER);
    if (this.isDisplay2) {
      this.drawTimetableService.playPreview(this.canvasDisplay2Id, ScreenCanvasIdEnum.TIMETABLE_OPERATION_MANAGER);
    }
  }

  /**
   * pause preview
   */
  public pausePreview() {
    this.isPlayPreview = false;
    this.dataService.sendData([`${MODULE_NAME[FIELD_COMPONENT.TimetableOperationManagerComponent]}:isPlayPreview`, false]);
    if (!this.deviceSelected || !this.timetableSelected || this.isViewMonitorMode) {
      return;
    }
    this.pausePreviewSubject.next();
    this.updateStateItemOnMenu.next();
    this.clearTimeoutDisplay(this.timeoutsDisplay1);
    this.drawTimetableService.pausePreview(this.canvasDisplay1Id, ScreenCanvasIdEnum.TIMETABLE_OPERATION_MANAGER);
    if (this.isDisplay2) {
      this.clearTimeoutDisplay(this.timeoutsDisplay2);
      this.drawTimetableService.pausePreview(this.canvasDisplay2Id, ScreenCanvasIdEnum.TIMETABLE_OPERATION_MANAGER);
    }
  }

  /**
   * next preview
   */
  public nextPreview() {
    if (
      !this.isPlayPreview ||
      this.isViewMonitorMode ||
      Object.values(this.activeScheduleRow).every(item => !item) ||
      this.deviceSelected.isManuallySetOperatingSystem
    ) {
      return;
    }
    this.pauseTimerCheckLessCurrentTimeSubject.next();
    this.currentIndexTimetableSchedule++;
    this.activeScheduleRow = this.getActiveScheduleRow(this.getSchedulesSortedByTime());
    this.drawSchedule();
    this.checkLessCurrentTime();

    // finish schedule
    this.previewFinishSchedule(this.activeScheduleRow);
  }

  /**
   * choose tool
   *
   * @param tool
   */
  public chooseTool(tool: PreviewToolEnum) {
    // pan tool is chosen
    if (tool == PreviewToolEnum.PAN) {
      this.isPan = !this.isPan;
      this.handleActiveStatusWhenToolChosen(
        this.divContainCanvas1.nativeElement,
        this.isPan,
        this.isZoom,
        this.CURSOR_MOVE,
        this.CURSOR_POINTER
      );
      if (this.isDisplay2) {
        this.handleActiveStatusWhenToolChosen(
          this.divContainCanvas2.nativeElement,
          this.isPan,
          this.isZoom,
          this.CURSOR_MOVE,
          this.CURSOR_POINTER
        );
      }
      // zoom pan is chosen
    } else {
      this.isZoom = !this.isZoom;
      this.handleActiveStatusWhenToolChosen(
        this.divContainCanvas1.nativeElement,
        this.isZoom,
        this.isPan,
        this.CURSOR_POINTER,
        this.CURSOR_MOVE
      );
      if (this.isDisplay2) {
        this.handleActiveStatusWhenToolChosen(
          this.divContainCanvas2.nativeElement,
          this.isZoom,
          this.isPan,
          this.CURSOR_POINTER,
          this.CURSOR_MOVE
        );
      }
    }
    // choose 2 tool at the same time
    if (this.isZoom && this.isPan) {
      this.renderer.setStyle(this.divContainCanvas1.nativeElement, this.CURSOR, this.CURSOR_MOVE);
      if (this.isDisplay2) {
        this.renderer.setStyle(this.divContainCanvas2.nativeElement, this.CURSOR, this.CURSOR_MOVE);
      }
    }
    this.panzoomDisplay1.setOptions({ disablePan: !this.isPan, disableZoom: !this.isZoom, force: true });
    if (this.isDisplay2) {
      this.panzoomDisplay2.setOptions({ disablePan: !this.isPan, disableZoom: !this.isZoom, force: true });
    }
  }

  /**
   * Handle active status when tool chosen
   *
   * @param nativeElement
   * @param isToolUnchosen
   * @param isToolChosen
   * @param cursorForToolChosen
   * @param cursorForToolUnchosen
   */
  private handleActiveStatusWhenToolChosen(
    nativeElement: any,
    isToolUnchosen: boolean,
    isToolChosen: boolean,
    cursorForToolChosen: string,
    cursorForToolUnchosen: string
  ): void {
    if (isToolChosen && !isToolUnchosen) {
      this.renderer.setStyle(nativeElement, this.CURSOR, cursorForToolChosen);
    } else {
      this.renderer.setStyle(nativeElement, this.CURSOR, isToolUnchosen ? cursorForToolUnchosen : this.CURSOR_DEFAULT);
    }
  }

  /**
   * subscribe event mouse wheel
   *
   * @param e
   * @returns
   */
  @HostListener('mousewheel', ['$event'])
  mouseWheel(e) {
    if (!this.isZoom) {
      return;
    }
    if (e.target.id.includes('timetable-operation-previewCanvas1')) {
      this.panzoomDisplay1.zoomWithWheel(e);
    }
    if (e.target.id.includes('timetable-operation-previewCanvas2')) {
      this.panzoomDisplay2.zoomWithWheel(e);
    }
  }

  /**
   * enlarge preview
   */
  public enlargePreview() {
    this.isEnlargePreview = !this.isEnlargePreview;
    this.calculateScaleTransformCanvas(
      _.get(this.timetableSelected?.templateDisplay1s, `[${this.templateSelectedTypeDisplay1}]`, undefined),
      this.divContainCanvas1,
      this.divPreviewDisplay1,
      DisplaysEnum.DISPLAY_1
    );
    if (this.isDisplay2) {
      this.calculateScaleTransformCanvas(
        _.get(this.timetableSelected?.templateDisplay2s, `[${this.templateSelectedTypeDisplay2}]`, undefined),
        this.divContainCanvas2,
        this.divPreviewDisplay2,
        DisplaysEnum.DISPLAY_2
      );
    }
  }

  /**
   * create canvas template
   *
   * @param template
   * @param canvasContainerDisplay
   * @param screenId
   * @param displayEnum
   */
  private createCanvasTemplate(template: Template, canvasContainerDisplay: any, screenId: string, displayEnum: DisplaysEnum) {
    if (!template) {
      return;
    }
    const canvas = this.renderer.createElement('canvas');
    canvas.id = displayEnum == DisplaysEnum.DISPLAY_1 ? `${screenId}-previewCanvas1` : `${screenId}-previewCanvas2`;
    canvas.style.position = 'absolute';
    canvas.style.background = '#000';
    canvas.style.width = template.width + 'px';
    canvas.style.height = template.height + 'px';
    if (this.isViewMonitorMode) {
      canvas.style.outline = '12px solid #6faadc';
    }
    canvas.width = template.width;
    canvas.height = template.height;
    this.renderer.appendChild(canvasContainerDisplay.nativeElement, canvas);
  }

  /**
   * create all canvas area template
   *
   * @param template
   * @param canvasContainerDisplay
   * @param screenId
   * @param displayEnum
   */
  private createAllCanvasAreaTemplate(template: Template, canvasContainerDisplay: any, screenId: string, displayEnum: DisplaysEnum) {
    template?.layers &&
      template.layers.forEach(layer => {
        layer.areas.forEach(area => {
          this.createCanvasArea(area, canvasContainerDisplay, screenId, displayEnum);
        });
      });
  }

  /**
   * create canvas area
   *
   * @param area
   * @param canvasContainerDisplay
   * @param screenId
   * @param display
   */
  private createCanvasArea(area: Area, canvasContainerDisplay: any, screenId: string, display: DisplaysEnum) {
    const canvas = this.renderer.createElement('canvas');
    canvas.id = display == DisplaysEnum.DISPLAY_1 ? `${screenId}-previewCanvas1-${area.id}` : `${screenId}-previewCanvas2-${area.id}`;
    canvas.style.position = 'absolute';
    canvas.style.zIndex = area.index;
    canvas.style.left = area.posX + 'px';
    canvas.style.top = area.posY + 'px';
    canvas.style.width = area.width + 'px';
    canvas.style.height = area.height + 'px';
    canvas.width = area.width;
    canvas.height = area.height;
    if (area.isOnClickEvent) {
      canvas.style.cursor = 'pointer';
      canvas.addEventListener('click', () => {
        if (
          canvasContainerDisplay.nativeElement.id == this.canvasDisplay1Id &&
          this.timetableSelected?.templateDisplay1s[area.onClickEventDestination]
        ) {
          this.toSwitchBetweenPageSubject.next({ key: this.canvasDisplay1Id, value: area.onClickEventDestination });
        }
        if (
          canvasContainerDisplay.nativeElement.id == this.canvasDisplay2Id &&
          this.timetableSelected?.templateDisplay2s[area.onClickEventDestination]
        ) {
          this.toSwitchBetweenPageSubject.next({ key: this.canvasDisplay2Id, value: area.onClickEventDestination });
        }
      });
    }
    this.renderer.appendChild(canvasContainerDisplay.nativeElement, canvas);
    area.canvas = canvas;
  }

  /**
   * draw preview mode preview
   */
  private drawPreviewModePreview(): void {
    this.panzoomDisplay1 = undefined;
    this.panzoomDisplay2 = undefined;
    this.drawTimetableService.clearTimeoutsStopDurationArea(true, ScreenCanvasIdEnum.TIMETABLE_OPERATION_MANAGER);
    this.drawDisplay(
      _.get(this.timetableSelected?.templateDisplay1s, `[${this.templateSelectedTypeDisplay1}]`, undefined),
      this.divContainCanvas1,
      this.divPreviewDisplay1,
      DisplaysEnum.DISPLAY_1,
      this.canvasDisplay1Id
    );
    if (this.isDisplay2) {
      this.drawDisplay(
        _.get(this.timetableSelected?.templateDisplay2s, `[${this.templateSelectedTypeDisplay2}]`, undefined),
        this.divContainCanvas2,
        this.divPreviewDisplay2,
        DisplaysEnum.DISPLAY_2,
        this.canvasDisplay2Id
      );
    }
    this.drawTimetableService.changeStatePlayPause(
      this.isPlayPreview,
      this.canvasDisplay1Id,
      ScreenCanvasIdEnum.TIMETABLE_OPERATION_MANAGER
    );
    this.drawTimetableService.changeStatePlayPause(
      this.isPlayPreview,
      this.canvasDisplay2Id,
      ScreenCanvasIdEnum.TIMETABLE_OPERATION_MANAGER
    );
  }

  /**
   * draw display
   *
   * @param template
   * @param divContainCanvas
   * @param divPreviewDisplay
   * @param displayEnum
   * @param canvasDisplayId
   * @returns
   */
  public async drawDisplay(
    template: Template,
    divContainCanvas: ElementRef,
    divPreviewDisplay: ElementRef,
    displayEnum: DisplaysEnum,
    canvasDisplayId: any
  ) {
    if (canvasDisplayId == this.canvasDisplay1Id) {
      this.timetablePositionRowDisplay1Set = new Set();
      this.subscribesGetDataExternal[displayEnum]?.unsubscribe();
      this.drawTimetableService.clearAllIntervalDrawsNewsDisplay1();
      this.drawTimetableService.clearAllThreadDrawTemplate(
        _.get(this.timetableSelected?.templateDisplay1s, `[${this.templateSelectedTypeDisplay1}]`, undefined),
        this.canvasDisplay1Id,
        ScreenCanvasIdEnum.TIMETABLE_OPERATION_MANAGER
      );
      this.linkAreasDisplay1 = Helper.getAllAreaTemplateForDisplay(this.timetableSelected?.templateDisplay1s).filter(area => !area.isFix);
      this.getAllAreasReferencePositionRow(displayEnum).forEach(area => {
        this.timetablePositionRowDisplay1Set.add(area.referencePositionRow);
      });
    } else {
      this.timetablePositionRowDisplay2Set = new Set();
      this.subscribesGetDataExternal[displayEnum]?.unsubscribe();
      this.drawTimetableService.clearAllIntervalDrawsNewsDisplay2();
      this.drawTimetableService.clearAllThreadDrawTemplate(
        _.get(this.timetableSelected?.templateDisplay2s, `[${this.templateSelectedTypeDisplay2}]`, undefined),
        this.canvasDisplay2Id,
        ScreenCanvasIdEnum.TIMETABLE_OPERATION_MANAGER
      );
      this.linkAreasDisplay2 = Helper.getAllAreaTemplateForDisplay(this.timetableSelected?.templateDisplay2s).filter(area => !area.isFix);
      this.getAllAreasReferencePositionRow(displayEnum).forEach(area => {
        this.timetablePositionRowDisplay2Set.add(area.referencePositionRow);
      });
    }
    this.drawTimetableService.clearIntervalsClock(canvasDisplayId, ScreenCanvasIdEnum.TIMETABLE_OPERATION_MANAGER);
    this.clearTimeoutDisplay(this.timeoutsDisplay1);
    this.clearTimeoutDisplay(this.timeoutsDisplay2);
    this.drawTimetableService.clearTimeoutsStopDurationArea(true, ScreenCanvasIdEnum.TIMETABLE_OPERATION_MANAGER);
    this.changeDetectorRef.detectChanges();
    Helper.clearNodeChild(divContainCanvas.nativeElement);
    if (!template) {
      // if (this.isViewMonitorMode) {
      //   // no data template => show black screen
      //   this.handleWhenNetworkNGMonitorMode();
      // }
      return;
    }
    this.createCanvasTemplate(template, divContainCanvas, this.SCREEN_ID, displayEnum);
    this.createAllCanvasAreaTemplate(template, divContainCanvas, this.SCREEN_ID, displayEnum);
    this.calculateScaleTransformCanvas(template, divContainCanvas, divPreviewDisplay, displayEnum);
    this.drawTimetableService.setupPreview(this.timetableSelected, undefined);
    this.drawTimetableService.resetData(this.templateOld, canvasDisplayId, ScreenCanvasIdEnum.TIMETABLE_OPERATION_MANAGER);
    const referencePositionColumnAreas = Helper.getReferencePositionColumnsByTemplates(this.timetableSelected?.templateDisplay1s);
    this.drawTimetableService.setDataPreviewTimetableOperationManager(
      this.activeScheduleRow,
      referencePositionColumnAreas,
      this.dynamicMessages,
      this.emergencyData
    );
    this.templateOld = undefined;
    if (this.isViewMonitorMode) {
      this.drawTimetableService.changeStartState(false, ScreenCanvasIdEnum.TIMETABLE_OPERATION_MANAGER);
    } else {
      this.drawTimetableService.changeStartState(true, ScreenCanvasIdEnum.TIMETABLE_OPERATION_MANAGER);
    }
    this.drawTimetableService.setAreaSwitchingTiming(this.areaSwitchingTiming);
    // MODE PREVIEW
    if (!this.isViewMonitorMode) {
      // draw schedule
      if (!this.deviceSelected.isManuallySetOperatingSystem) {
        this.activeScheduleRow = this.getActiveScheduleRow(this.getSchedulesSortedByTime());
      }
      // if finish schedule => draw fix area timing on
      if (this.schedules?.length && Object.values(this.activeScheduleRow).every(item => !item)) {
        this.drawTimetableService.drawPreviewFixAreaTimingOn(
          Helper.getAllAreaTemplate(template).filter(area => area.isFix && area.isTimingOn),
          this.renderer,
          canvasDisplayId,
          ScreenCanvasIdEnum.TIMETABLE_OPERATION_MANAGER,
          true,
          template
        );
      }
      const referencePositionColumnAreas = Helper.getReferencePositionColumnsByTemplates(this.timetableSelected?.templateDisplay1s);
      this.drawTimetableService.setDataPreviewTimetableOperationManager(
        this.activeScheduleRow,
        referencePositionColumnAreas,
        this.dynamicMessages,
        this.emergencyData
      );
      this.drawTimetableService.setDataIsOnEmergency(this.deviceSelected.isOnEmergency || this.isEditEmergencyMode);
      // draw index word by schedule
      let indexWordAreas = this.getAllAreasIndexWord(displayEnum);
      if (indexWordAreas.length != 0) {
        this.indexWordService
          .getIndexWordsByNameAndGroupId(this.getParamForGetIndexWord(indexWordAreas, this.activeScheduleRow))
          .subscribe(indexWords => {
            Helper.setDataIndexWordForAreas(indexWordAreas, indexWords);
            this.drawTimetableService.setDataPreviewTimetableOperationManager(null, null, null, null, indexWordAreas);
            this.drawTimetableService.drawPreview(template, this.renderer, canvasDisplayId, ScreenCanvasIdEnum.TIMETABLE_OPERATION_MANAGER);
            this.drawTimetableService.changeStatePlayPause(
              this.isPlayPreview,
              canvasDisplayId,
              ScreenCanvasIdEnum.TIMETABLE_OPERATION_MANAGER
            );
          });
      } else {
        this.drawTimetableService.drawPreview(template, this.renderer, canvasDisplayId, ScreenCanvasIdEnum.TIMETABLE_OPERATION_MANAGER);
      }
    }
    // draw external content
    const destination = (template.templateType as unknown) as DestinationEnum;
    this.getFullDataExternalContentToPreview(canvasDisplayId, this.getDataExternalSetting(destination, canvasDisplayId), destination);
    // draw destination index
    if (this.isPlayPreview && this.isViewMonitorMode) {
      this.drawDisplayDestinationIndex(template, canvasDisplayId);
    }
    if (this.isPan && !this.isZoom) {
      this.renderer.setStyle(divContainCanvas.nativeElement, 'cursor', 'pointer');
    } else if ((this.isZoom && !this.isPan) || (this.isPan && this.isZoom)) {
      this.renderer.setStyle(divContainCanvas.nativeElement, 'cursor', 'move');
    } else {
      this.renderer.setStyle(divContainCanvas.nativeElement, 'cursor', 'default');
    }
  }

  /**
   * get data external content of timetable
   *
   * @param destinationEnum type of template
   * @param canvasDisplayId id area draw
   * @returns data external content of timetable
   */
  private getDataExternalSetting(destinationEnum: DestinationEnum, canvasDisplayId: string): DataExternalSetting[] {
    if (canvasDisplayId == this.canvasDisplay1Id) {
      switch (destinationEnum) {
        case DestinationEnum.MAIN:
          return this.timetableSelected.displayTemplate1.externalContentMainPage;
        case DestinationEnum.SUB_PAGE_1:
          return this.timetableSelected.displayTemplate1.externalContentPage1;
        case DestinationEnum.SUB_PAGE_2:
          return this.timetableSelected.displayTemplate1.externalContentPage2;
        case DestinationEnum.SUB_PAGE_3:
          return this.timetableSelected.displayTemplate1.externalContentPage3;
        case DestinationEnum.SUB_PAGE_4:
          return this.timetableSelected.displayTemplate1.externalContentPage4;
        case DestinationEnum.SUB_PAGE_5:
          return this.timetableSelected.displayTemplate1.externalContentPage5;
        case DestinationEnum.SUB_PAGE_6:
          return this.timetableSelected.displayTemplate1.externalContentPage6;
        case DestinationEnum.SUB_PAGE_7:
          return this.timetableSelected.displayTemplate1.externalContentPage7;
        case DestinationEnum.SUB_PAGE_8:
          return this.timetableSelected.displayTemplate1.externalContentPage8;
        case DestinationEnum.SUB_PAGE_9:
          return this.timetableSelected.displayTemplate1.externalContentPage9;
        case DestinationEnum.EMERGENCY:
          return this.timetableSelected.displayTemplate1.externalContentEmergencyPage;
        default:
          return null;
      }
    } else {
      switch (destinationEnum) {
        case DestinationEnum.MAIN:
          return this.timetableSelected.displayTemplate2.externalContentMainPage;
        case DestinationEnum.SUB_PAGE_1:
          return this.timetableSelected.displayTemplate2.externalContentPage1;
        case DestinationEnum.SUB_PAGE_2:
          return this.timetableSelected.displayTemplate2.externalContentPage2;
        case DestinationEnum.SUB_PAGE_3:
          return this.timetableSelected.displayTemplate2.externalContentPage3;
        case DestinationEnum.SUB_PAGE_4:
          return this.timetableSelected.displayTemplate2.externalContentPage4;
        case DestinationEnum.SUB_PAGE_5:
          return this.timetableSelected.displayTemplate2.externalContentPage5;
        case DestinationEnum.SUB_PAGE_6:
          return this.timetableSelected.displayTemplate2.externalContentPage6;
        case DestinationEnum.SUB_PAGE_7:
          return this.timetableSelected.displayTemplate2.externalContentPage7;
        case DestinationEnum.SUB_PAGE_8:
          return this.timetableSelected.displayTemplate2.externalContentPage8;
        case DestinationEnum.SUB_PAGE_9:
          return this.timetableSelected.displayTemplate2.externalContentPage9;
        case DestinationEnum.EMERGENCY:
          return this.timetableSelected.displayTemplate2.externalContentEmergencyPage;
        default:
          return null;
      }
    }
  }

  /**
   * get full data picture to draw
   *
   * @param canvasDisplayId id area draw
   * @param dataExternalSettings list data external setting(id area, id external content)
   * @param typeOfTemplate type of template
   * @returns
   */
  private async getFullDataExternalContentToPreview(
    canvasDisplayId: string,
    dataExternalSettings: DataExternalSetting[],
    typeOfTemplate: DestinationEnum
  ) {
    if (!dataExternalSettings) {
      return;
    }
    // get full data to draw
    let display =
      canvasDisplayId == this.canvasDisplay1Id
        ? _.get(this.timetableSelected?.templateDisplay1s, `[${this.templateSelectedTypeDisplay1}]`, undefined)
        : _.get(this.timetableSelected?.templateDisplay2s, `[${this.templateSelectedTypeDisplay2}]`, undefined);

    let subscription = this.pictureAreaService.getFullDataPictureAreaExternalContent(dataExternalSettings).subscribe(
      async dataResponse => {
        this.drawTimetableService.setDataPreviewTimetableEditor(null, dataResponse, this.pictureAreaService);
        let areaExternalContent = Helper.getAllAreaReferenceExternalContent(display);
        let areaExternalContentOn = Helper.getAreasDisplayOnPreview(areaExternalContent, display, this.areaSwitchingTiming);
        this.drawTimetableService.drawExternalContent(
          canvasDisplayId,
          dataResponse,
          display,
          this.pictureAreaService,
          ScreenCanvasIdEnum.TIMETABLE_OPERATION_MANAGER,
          areaExternalContentOn
        );
      },
      () => this.handleErrorFromApi()
    );
    // set intervals and subscription for display drawing
    if (canvasDisplayId !== this.canvasDisplay1Id) {
      this.subscribesGetDataExternal[DisplaysEnum.DISPLAY_2] = subscription;
    } else {
      this.subscribesGetDataExternal[DisplaysEnum.DISPLAY_1] = subscription;
    }
  }

  /**
   * draw display auto transition
   *
   * @param display
   * @param canvasDisplayId
   * @returns
   */
  private drawDisplayDestinationIndex(display: Template, canvasDisplayId: any) {
    if (!display?.isAutoTransition) {
      return;
    }
    let isCanvasDisplay1: boolean = canvasDisplayId == this.canvasDisplay1Id;
    let destinationIndex = display.destination;
    let timeout = setTimeout(() => {
      let display1Template = _.get(this.timetableSelected?.templateDisplay1s, `[${destinationIndex}]`, undefined);
      let display2Template = _.get(this.timetableSelected?.templateDisplay2s, `[${destinationIndex}]`, undefined);
      if ((isCanvasDisplay1 && display1Template) || (!isCanvasDisplay1 && display2Template)) {
        if (isCanvasDisplay1) {
          // clear interval and time out list
          this.drawTimetableService.clearAllThreadDrawTemplate(
            _.get(this.timetableSelected?.templateDisplay1s, `[${this.templateSelectedTypeDisplay1}]`, undefined),
            this.canvasDisplay1Id,
            ScreenCanvasIdEnum.TIMETABLE_OPERATION_MANAGER
          );
        } else {
          this.drawTimetableService.clearAllThreadDrawTemplate(
            _.get(this.timetableSelected?.templateDisplay2s, `[${this.templateSelectedTypeDisplay2}]`, undefined),
            this.canvasDisplay2Id,
            ScreenCanvasIdEnum.TIMETABLE_OPERATION_MANAGER
          );
        }
        this.drawTimetableService.clearTimeoutsStopDurationArea(true, ScreenCanvasIdEnum.TIMETABLE_OPERATION_MANAGER);
        this.drawTimetableService.changeStartState(false, ScreenCanvasIdEnum.TIMETABLE_OPERATION_MANAGER);
        // draw display
        this.drawDisplay(
          isCanvasDisplay1 ? display1Template : display2Template,
          isCanvasDisplay1 ? this.divContainCanvas1 : this.divContainCanvas2,
          isCanvasDisplay1 ? this.divPreviewDisplay1 : this.divPreviewDisplay2,
          isCanvasDisplay1 ? DisplaysEnum.DISPLAY_1 : DisplaysEnum.DISPLAY_2,
          isCanvasDisplay1 ? this.canvasDisplay1Id : this.canvasDisplay2Id
        );
        this.templateSelectedTypeDisplay1 = isCanvasDisplay1 ? destinationIndex : this.templateSelectedTypeDisplay1;
        this.templateSelectedTypeDisplay2 = !isCanvasDisplay1 ? destinationIndex : this.templateSelectedTypeDisplay2;
        if (this.isPlayPreview) {
          this.drawTimetableService.playPreview(canvasDisplayId, ScreenCanvasIdEnum.TIMETABLE_OPERATION_MANAGER);
        } else {
          this.drawTimetableService.pausePreview(canvasDisplayId, ScreenCanvasIdEnum.TIMETABLE_OPERATION_MANAGER);
        }
      }
    }, display.transitionTime * 1000);
    if (isCanvasDisplay1) {
      this.timeoutsDisplay1.push(timeout);
    } else {
      this.timeoutsDisplay2.push(timeout);
    }
  }

  /**
   * handle event click area on canvas
   *
   * @param divContainCanvas
   * @param canvasDisplayId
   * @param displayTemplates
   * @param destinationDisplay
   */
  private handleEventClickAreaOnCanvas(
    divContainCanvas: any,
    canvasDisplayId: string,
    displayTemplates: Template[],
    destinationDisplay: DestinationEnum
  ) {
    if (displayTemplates && !displayTemplates[destinationDisplay]) {
      return;
    }
    this.drawTimetableService.clearTimeoutsStopDurationArea(true, ScreenCanvasIdEnum.TIMETABLE_OPERATION_MANAGER);
    this.drawTimetableService.changeStartState(false, ScreenCanvasIdEnum.TIMETABLE_OPERATION_MANAGER);
    this.drawDisplay(
      displayTemplates[destinationDisplay],
      divContainCanvas,
      canvasDisplayId == this.canvasDisplay1Id ? this.divPreviewDisplay1 : this.divPreviewDisplay2,
      canvasDisplayId == this.canvasDisplay1Id ? DisplaysEnum.DISPLAY_1 : DisplaysEnum.DISPLAY_2,
      canvasDisplayId
    );
    if (canvasDisplayId == this.canvasDisplay1Id) {
      this.templateSelectedTypeDisplay1 = destinationDisplay;
    } else {
      this.templateSelectedTypeDisplay2 = destinationDisplay;
    }
    this.drawTimetableService.playPreview(canvasDisplayId, ScreenCanvasIdEnum.TIMETABLE_OPERATION_MANAGER);
  }

  /**
   * calculate scale transform canvas
   *
   * @param template
   */
  private calculateScaleTransformCanvas(template: Template, divContainCanvas: any, divPreview: ElementRef, displayEnum: DisplaysEnum) {
    if (!template) {
      return;
    }
    this.changeDetectorRef.detectChanges();
    const maxHeight = divPreview.nativeElement.clientHeight - 125;
    const maxWidth = divPreview.nativeElement.clientWidth - 24;
    let scaleTransform = { scaleX: 1, scaleY: 1 };
    if (template.width > maxWidth) {
      scaleTransform.scaleX = maxWidth / template.width;
    }
    if (template.height > maxHeight) {
      scaleTransform.scaleY = maxHeight / template.height;
    }
    const scale = Math.min(scaleTransform.scaleX, scaleTransform.scaleY);
    this.renderer.setStyle(divContainCanvas.nativeElement, 'transform', 'scale(' + scale + ')');
    const realHeightTemplate = template.height * scale;
    const realWidthTemplate = template.width * scale;
    const height = (maxHeight - realHeightTemplate) / 2;
    const width = (maxWidth - realWidthTemplate) / 2;
    divContainCanvas.nativeElement.style.marginTop = height + 'px';
    divContainCanvas.nativeElement.style.marginLeft = width + 'px';
    if (displayEnum == DisplaysEnum.DISPLAY_1) {
      if (this.panzoomDisplay1) {
        this.panzoomDisplay1.reset({
          startScale: scale,
          minScale: 0.1,
          maxScale: 2,
          startX: 0,
          startY: 0
        });
      } else {
        this.panzoomDisplay1 = Panzoom(this.divContainCanvas1.nativeElement, { startScale: scale, minScale: 0.1, maxScale: 2 });
      }
      this.panzoomDisplay1.setOptions({ disablePan: !this.isPan, disableZoom: !this.isZoom, force: true });
    } else if (this.isDisplay2) {
      if (this.panzoomDisplay2) {
        this.panzoomDisplay2.reset({
          startScale: scale,
          minScale: 0.1,
          maxScale: 2,
          startX: 0,
          startY: 0
        });
      } else {
        this.panzoomDisplay2 = Panzoom(this.divContainCanvas2.nativeElement, { startScale: scale, minScale: 0.1, maxScale: 2 });
      }
      this.panzoomDisplay2.setOptions({ disablePan: !this.isPan, disableZoom: !this.isZoom, force: true });
    }
  }

  /**
   * get buttons preview
   * @param displayTemplate
   * @returns
   */
  private getButtonsPreview(displayTemplate: DisplayTemplate): Array<{ key: DestinationEnum; value: string }> {
    if (!displayTemplate) {
      return;
    }
    let buttonsPreview = new Array<{ key: DestinationEnum; value: string }>();
    if (displayTemplate.idMainPage) {
      buttonsPreview.push({ key: DestinationEnum.MAIN, value: this.translateService.instant('timetable-operation-manager.buttons.main') });
    }
    if (displayTemplate.idSubPage1) {
      buttonsPreview.push({
        key: DestinationEnum.SUB_PAGE_1,
        value: this.translateService.instant('timetable-operation-manager.buttons.sub1')
      });
    }
    if (displayTemplate.idSubPage2) {
      buttonsPreview.push({
        key: DestinationEnum.SUB_PAGE_2,
        value: this.translateService.instant('timetable-operation-manager.buttons.sub2')
      });
    }
    if (displayTemplate.idSubPage3) {
      buttonsPreview.push({
        key: DestinationEnum.SUB_PAGE_3,
        value: this.translateService.instant('timetable-operation-manager.buttons.sub3')
      });
    }
    if (displayTemplate.idSubPage4) {
      buttonsPreview.push({
        key: DestinationEnum.SUB_PAGE_4,
        value: this.translateService.instant('timetable-operation-manager.buttons.sub4')
      });
    }
    if (displayTemplate.idSubPage5) {
      buttonsPreview.push({
        key: DestinationEnum.SUB_PAGE_5,
        value: this.translateService.instant('timetable-operation-manager.buttons.sub5')
      });
    }
    if (displayTemplate.idSubPage6) {
      buttonsPreview.push({
        key: DestinationEnum.SUB_PAGE_6,
        value: this.translateService.instant('timetable-operation-manager.buttons.sub6')
      });
    }
    if (displayTemplate.idSubPage7) {
      buttonsPreview.push({
        key: DestinationEnum.SUB_PAGE_7,
        value: this.translateService.instant('timetable-operation-manager.buttons.sub7')
      });
    }
    if (displayTemplate.idSubPage8) {
      buttonsPreview.push({
        key: DestinationEnum.SUB_PAGE_8,
        value: this.translateService.instant('timetable-operation-manager.buttons.sub8')
      });
    }
    if (displayTemplate.idSubPage9) {
      buttonsPreview.push({
        key: DestinationEnum.SUB_PAGE_9,
        value: this.translateService.instant('timetable-operation-manager.buttons.sub9')
      });
    }
    if (displayTemplate.idEmergencyPage) {
      buttonsPreview.push({
        key: DestinationEnum.EMERGENCY,
        value: this.translateService.instant('timetable-operation-manager.buttons.emergency')
      });
    }
    return buttonsPreview;
  }

  /**
   * convert data dynamic message
   *
   * @param dynamicMessageData
   * @returns
   */
  private convertDataDynamicMessageFromServer(dynamicMessageData: any) {
    if (!dynamicMessageData) {
      return;
    }
    let dynamicMessage = new DynamicMessage();
    dynamicMessage.id = dynamicMessageData['id'];
    dynamicMessage.device = this.devices.find(device => device.id == dynamicMessageData['deviceId']);
    if (dynamicMessageData['textArea']) {
      dynamicMessage.textArea = Helper.convertDataTextArea(dynamicMessageData['textArea']);
      dynamicMessage.message = dynamicMessageData['message'] ?? '';
    }
    if (dynamicMessageData['pictureArea']) {
      dynamicMessage.pictureArea = Helper.convertDataPictureArea(dynamicMessageData['pictureArea']);
      dynamicMessage.media = dynamicMessageData['media'] ?? null;
    }
    return dynamicMessage;
  }

  /**
   * clear all time out
   *
   * @param timeouts
   */
  private clearTimeoutDisplay(timeouts: any[]) {
    for (let i = 0; i < timeouts.length; i++) {
      clearTimeout(timeouts[i]);
    }
    timeouts = [];
  }

  /**
   * handle error from Api
   *
   * @param error
   */
  private handleErrorFromApi() {
    this.dialogService.showDialog(DialogMessageComponent, {
      data: {
        title: this.translateService.instant('dialog-message.error-title'),
        text: this.translateService.instant('dialog-message.an-error')
      }
    });
  }

  /**
   * clear all thread draw news page
   *
   * @param intervals
   */
  private clearAllIntervalDrawsNews(intervals: any) {
    intervals.forEach(subscription => subscription?.unsubscribe());
  }

  /**
   * get area external content by id
   *
   * @param template
   * @param idArea of external content
   * @returns area external content
   */
  private getAreaExternalContent(template: Template, idArea: number): PictureArea {
    if (!template) {
      return null;
    }
    for (let layer of template?.layers) {
      for (let area of layer?.areas) {
        if (area.id === idArea) {
          return area as PictureArea;
        }
      }
    }
  }

  /**
   * convert data schedule operation info
   *
   * @param scheduleOperationInfoData
   * @returns
   */
  private convertDataScheduleOperationInfo(scheduleOperationInfoData: any): ScheduleOperationInfo {
    return {
      id: scheduleOperationInfoData['id'],
      timetableContentDayId: scheduleOperationInfoData['timetableContentDayId'],
      operationInfo: JSON.parse(scheduleOperationInfoData['operationInfo'])
    };
  }

  /**
   * convert data schedule display index
   *
   * @param scheduleDisplayIndexData
   * @returns
   */
  private convertDataScheduleDisplayIndex(scheduleDisplayIndexData: any): ScheduleDisplayIndex {
    return {
      id: scheduleDisplayIndexData['id'],
      timetableContentDayId: scheduleDisplayIndexData['timetableContentDayId'],
      displayIndex: JSON.parse(scheduleDisplayIndexData['displayIndex'])
    };
  }

  /**
   * get all areas emergency message
   *
   * @param displayEnum
   * @returns
   */
  private getAllAreasIndexWord(displayEnum: DisplaysEnum): Area[] {
    const linkAreas = displayEnum == DisplaysEnum.DISPLAY_1 ? this.linkAreasDisplay1 : this.linkAreasDisplay2;
    if (!linkAreas) {
      return [];
    }
    return linkAreas.filter(
      area =>
        (area as TextArea).linkReferenceData == LinkDataTextEnum.INDEX_WORD ||
        (area as PictureArea).attribute == LinkDataPictureEnum.INDEX_WORD
    );
  }

  /**
   * get all areas dynamic message
   *
   * @param displayEnum
   * @returns
   */
  private getAllAreasDynamicMessage(displayEnum: DisplaysEnum): Area[] {
    const linkAreas = displayEnum == DisplaysEnum.DISPLAY_1 ? this.linkAreasDisplay1 : this.linkAreasDisplay2;
    if (!linkAreas) {
      return [];
    }
    return linkAreas
      .filter(
        area =>
          (area.checkTypeTextArea() && (area as TextArea).linkReferenceData == LinkDataTextEnum.DYNAMIC_MESSAGE) ||
          (!area.checkTypeTextArea() && (area as PictureArea).attribute == LinkDataPictureEnum.DYNAMIC_MESSAGE)
      )
      .map(area => {
        return area;
      });
  }

  /**
   * get all areas emergency message
   *
   * @param displayEnum
   * @param isText
   * @param isPicture
   * @returns
   */
  private getAllAreasEmergencyMessage(displayEnum: DisplaysEnum, isText?: boolean, isPicture?: boolean): Area[] {
    const linkAreas = displayEnum == DisplaysEnum.DISPLAY_1 ? this.linkAreasDisplay1 : this.linkAreasDisplay2;
    if (!linkAreas) {
      return [];
    }
    if (isText) {
      return linkAreas.filter(area => (area as TextArea).linkReferenceData == LinkDataTextEnum.EMERGENCY_MESSAGE);
    }
    if (isPicture) {
      return linkAreas.filter(area => (area as PictureArea).attribute == LinkDataPictureEnum.EMERGENCY_MESSAGE);
    }
    return linkAreas.filter(
      area =>
        (area as TextArea).linkReferenceData == LinkDataTextEnum.EMERGENCY_MESSAGE ||
        (area as PictureArea).attribute == LinkDataPictureEnum.EMERGENCY_MESSAGE
    );
  }

  /**
   * get all areas reference position row
   *
   * @param displayEnum
   * @returns
   */
  private getAllAreasReferencePositionRow(displayEnum: DisplaysEnum): Area[] {
    const linkAreas = displayEnum == DisplaysEnum.DISPLAY_1 ? this.linkAreasDisplay1 : this.linkAreasDisplay2;
    if (!linkAreas) {
      return [];
    }
    return linkAreas
      .filter(
        area =>
          area.getArea().linkReferenceData == LinkDataTextEnum.TIMETABLE ||
          area.getArea().linkReferenceData == LinkDataTextEnum.INDEX_WORD ||
          area.getArea().attribute == LinkDataPictureEnum.INDEX_WORD
      )
      .map(area => {
        return area;
      });
  }

  /**
   * get all areas timetable
   *
   * @param displayEnum
   * @returns
   */
  private getAllAreasSchedule(displayEnum: DisplaysEnum): TextArea[] {
    const linkAreas = displayEnum == DisplaysEnum.DISPLAY_1 ? this.linkAreasDisplay1 : this.linkAreasDisplay2;
    if (!linkAreas) {
      return [];
    }
    return linkAreas
      .filter(
        area =>
          (area.checkTypeTextArea() && (area as TextArea).linkReferenceData == LinkDataTextEnum.TIMETABLE) ||
          (area as TextArea).linkReferenceData == LinkDataTextEnum.OPERATION_INFO
      )
      .map(area => {
        return <TextArea>area;
      });
  }

  /**
   * get all areas operation info
   *
   * @param displayEnum
   * @returns
   */
  private getAllAreasOperationInfo(displayEnum: DisplaysEnum): TextArea[] {
    const linkAreas = displayEnum == DisplaysEnum.DISPLAY_1 ? this.linkAreasDisplay1 : this.linkAreasDisplay2;
    if (!linkAreas) {
      return [];
    }
    return linkAreas
      .filter(area => area.checkTypeTextArea() && (area as TextArea).linkReferenceData == LinkDataTextEnum.OPERATION_INFO)
      .map(area => {
        return <TextArea>area;
      });
  }

  /**
   * get list name index word and list group index word
   *
   * @param areas
   * @param activeScheduleRow
   * @returns list name index word and list group index word
   */
  private getParamForGetIndexWord(areas: Area[], activeScheduleRow: ActiveScheduleRow): any {
    let listNameIndexWord = [];
    let listGroupId = [];
    const referencePositionColumnAreas = Helper.getReferencePositionColumnsByTemplates(this.timetableSelected?.templateDisplay1s);
    areas.forEach(area => {
      let nameIndexWord = this.getNameIndexWordFromSchedule(
        area,
        activeScheduleRow,
        referencePositionColumnAreas.find(reference => area.referencePositionColumn + 1 === reference)
      );
      listGroupId.push(area.indexWordGroupId);
      listNameIndexWord.push(nameIndexWord);
    });
    return [listGroupId, listNameIndexWord];
  }

  /**
   * get name index word from schedule
   *
   * @param area
   * @param activeScheduleRow
   * @param position
   * @returns
   */
  private getNameIndexWordFromSchedule(area: Area, activeScheduleRow: ActiveScheduleRow, position: number): any {
    if (position == undefined || !activeScheduleRow) {
      return Constant.EMPTY;
    }
    if (area?.referencePositionRow || area.referencePositionRow == 0) {
      return activeScheduleRow[`${this.CURRENT}_${area.referencePositionRow}`]
        ? activeScheduleRow[`${this.CURRENT}_${area.referencePositionRow}`].list[position]
        : null;
    }
  }

  /**
   * get active schedule row
   *
   * @param schedules
   */
  private getActiveScheduleRow(schedules: Schedule[]) {
    let activeScheduleRow = new ActiveScheduleRow();
    const timetablePositionRowSet = Helper.mergeSet(this.timetablePositionRowDisplay1Set, this.timetablePositionRowDisplay2Set);
    schedules?.forEach((schedule, index) => {
      if (schedule) {
        schedule[this.ROW_NAME] = undefined;
        if (!timetablePositionRowSet.has(index)) {
          return;
        }
        schedule[this.ROW_NAME] = this.rowName[index].name;
        activeScheduleRow[`current_${index}`] = schedule;
      }
    });
    return activeScheduleRow;
  }

  /**
   * draw schedule
   */
  private drawSchedule() {
    this.drawTimetableService.setDataPreviewTimetableOperationManager(this.activeScheduleRow);
    this.drawTimetableService.clearTimeoutsStopDurationArea(false, ScreenCanvasIdEnum.TIMETABLE_OPERATION_MANAGER, [
      LinkDataTextEnum.INDEX_WORD,
      LinkDataTextEnum.TIMETABLE,
      LinkDataTextEnum.OPERATION_INFO
    ]);
    // clear old area time table
    this.drawTimetableService.clearAreas(
      this.getAllAreasSchedule(DisplaysEnum.DISPLAY_1),
      this.canvasDisplay1Id,
      ScreenCanvasIdEnum.TIMETABLE_OPERATION_MANAGER
    );
    let display1 = _.get(this.timetableSelected?.templateDisplay1s, `[${this.templateSelectedTypeDisplay1}]`, undefined);
    const referencePositionColumnAreas = Helper.getReferencePositionColumnsByTemplates(this.timetableSelected?.templateDisplay1s);
    // draw schedules for display 1
    this.drawTimetableSchedulesOperation(referencePositionColumnAreas, DisplaysEnum.DISPLAY_1, this.canvasDisplay1Id, display1);
    if (this.isDisplay2) {
      // clear old area time table
      this.drawTimetableService.clearAreas(
        this.getAllAreasSchedule(DisplaysEnum.DISPLAY_2),
        this.canvasDisplay2Id,
        ScreenCanvasIdEnum.TIMETABLE_OPERATION_MANAGER
      );
      // draw schedules for display 2
      let display2 = _.get(this.timetableSelected?.templateDisplay2s, `[${this.templateSelectedTypeDisplay2}]`, undefined);
      this.drawTimetableSchedulesOperation(referencePositionColumnAreas, DisplaysEnum.DISPLAY_2, this.canvasDisplay2Id, display2);
    }
  }

  /**
   * Draw timetable schedules operation
   * @param referencePositionColumnAreas
   * @param displaysEnum
   * @param canvasDisplayId
   */
  private drawTimetableSchedulesOperation(
    referencePositionColumnAreas: number[],
    displaysEnum: DisplaysEnum,
    canvasDisplayId: any,
    template: Template
  ): void {
    if (!template) {
      return;
    }
    // draw schedule (timetable, operation)
    const areasSchedule = this.getAllAreasSchedule(displaysEnum);
    let areasScheduleLayerOff = Helper.getAreasOfLayerOnOff(areasSchedule, template, this.areaSwitchingTiming);
    this.drawTimetableService.drawSchedule(
      this.activeScheduleRow,
      areasScheduleLayerOff as TextArea[],
      canvasDisplayId,
      ScreenCanvasIdEnum.TIMETABLE_OPERATION_MANAGER,
      referencePositionColumnAreas
    );
    let areasScheduleLayerOn = Helper.getAreasOfLayerOnOff(areasSchedule, template, this.areaSwitchingTiming, true);
    this.drawTimetableService.drawScheduleOn(
      this.activeScheduleRow,
      areasScheduleLayerOn as TextArea[],
      canvasDisplayId,
      ScreenCanvasIdEnum.TIMETABLE_OPERATION_MANAGER,
      referencePositionColumnAreas
    );

    // draw index word
    let areasIndexWordDisplay = this.getAllAreasIndexWord(displaysEnum);
    this.drawTimetableService.clearMediaIndexWords();
    if (areasIndexWordDisplay.length != 0) {
      this.indexWordService
        .getIndexWordsByNameAndGroupId(this.getParamForGetIndexWord(areasIndexWordDisplay, this.activeScheduleRow))
        .subscribe(indexWords => {
          // clear old area index world
          this.drawTimetableService.clearAreas(areasIndexWordDisplay, canvasDisplayId, ScreenCanvasIdEnum.TIMETABLE_OPERATION_MANAGER);
          Helper.setDataIndexWordForAreas(areasIndexWordDisplay, indexWords);
          this.drawTimetableService.setDataPreviewTimetableOperationManager(null, null, null, null, areasIndexWordDisplay);
          // draw off
          let areasIndexWordLayerOff = Helper.getAreasOfLayerOnOff(areasIndexWordDisplay, template, this.areaSwitchingTiming);
          this.drawTimetableService.drawIndexWordBySchedule(
            areasIndexWordLayerOff,
            canvasDisplayId,
            ScreenCanvasIdEnum.TIMETABLE_OPERATION_MANAGER,
            template
          );
          // draw on
          let areasIndexWordLayerOn = Helper.getAreasOfLayerOnOff(areasIndexWordDisplay, template, this.areaSwitchingTiming, true);
          this.drawTimetableService.drawIndexWordByScheduleOn(
            areasIndexWordLayerOn,
            canvasDisplayId,
            ScreenCanvasIdEnum.TIMETABLE_OPERATION_MANAGER,
            template
          );
        });
    }
  }

  /**
   * re draw dynamic messages
   *
   * @param dynamicMessages
   */
  private reDrawDynamicMessage(dynamicMessages: DynamicMessage[]) {
    this.drawTimetableService.clearTimeoutsStopDurationArea(false, ScreenCanvasIdEnum.TIMETABLE_OPERATION_MANAGER, [
      LinkDataTextEnum.DYNAMIC_MESSAGE
    ]);
    this.drawTimetableService.changeStartState(true, ScreenCanvasIdEnum.TIMETABLE_OPERATION_MANAGER);
    // draw dynamic messages
    this.drawDynamicMessages(dynamicMessages.filter(dynamic => dynamic.device.id == this.deviceSelected.id));
  }

  /**
   * draw dynamic messages
   *
   * @param dynamicMessages
   */
  private drawDynamicMessages(dynamicMessages: DynamicMessage[]) {
    const areasDisplay1 = this.getAllAreasDynamicMessage(DisplaysEnum.DISPLAY_1);
    this.drawTimetableService.setDataPreviewTimetableOperationManager(null, null, dynamicMessages);
    // draw area display 1
    this.drawTimetableService.clearAreas(areasDisplay1, this.canvasDisplay1Id, ScreenCanvasIdEnum.TIMETABLE_OPERATION_MANAGER);
    let display1 = _.get(this.timetableSelected?.templateDisplay1s, `[${this.templateSelectedTypeDisplay1}]`, undefined);
    this.drawAreasDynamicMessageForDisplay(display1, areasDisplay1, this.canvasDisplay1Id, dynamicMessages);

    if (this.isDisplay2) {
      const areasDisplay2 = this.getAllAreasDynamicMessage(DisplaysEnum.DISPLAY_2);
      this.drawTimetableService.clearAreas(areasDisplay2, this.canvasDisplay2Id, ScreenCanvasIdEnum.TIMETABLE_OPERATION_MANAGER);
      // draw area display 2
      let display2 = _.get(this.timetableSelected?.templateDisplay2s, `[${this.templateSelectedTypeDisplay2}]`, undefined);
      this.drawAreasDynamicMessageForDisplay(display2, areasDisplay2, this.canvasDisplay2Id, dynamicMessages);
    }
  }

  /**
   * draw areas dynamic message for display
   * @param display
   * @param areasDynamicMessage
   * @param canvasDisplayId
   * @param dynamicMessages
   */
  private drawAreasDynamicMessageForDisplay(
    display: Template,
    areasDynamicMessage: Area[],
    canvasDisplayId: string,
    dynamicMessages: DynamicMessage[]
  ): void {
    // draw layer off
    let areasDynamicMessageLayerOff = Helper.getAreasOfLayerOnOff(areasDynamicMessage, display, this.areaSwitchingTiming);
    this.drawTimetableService.drawDynamicMessages(
      dynamicMessages,
      areasDynamicMessageLayerOff,
      canvasDisplayId,
      ScreenCanvasIdEnum.TIMETABLE_OPERATION_MANAGER,
      display
    );

    // draw layer on
    let areasDynamicMessageLayerOn = Helper.getAreasOfLayerOnOff(areasDynamicMessage, display, this.areaSwitchingTiming, true);
    this.drawTimetableService.drawDynamicMessagesOn(
      dynamicMessages,
      areasDynamicMessageLayerOn,
      canvasDisplayId,
      ScreenCanvasIdEnum.TIMETABLE_OPERATION_MANAGER,
      display
    );
  }

  /**
   * re draw emergency
   *
   * @param emergencyData
   */
  private reDrawEmergency(emergencyData: EmergencyData) {
    this.drawTimetableService.clearTimeoutsStopDurationArea(false, ScreenCanvasIdEnum.TIMETABLE_OPERATION_MANAGER, [
      LinkDataTextEnum.EMERGENCY_MESSAGE
    ]);
    this.drawTimetableService.changeStartState(true, ScreenCanvasIdEnum.TIMETABLE_OPERATION_MANAGER);
    // draw emergency
    this.drawEmergency(emergencyData);
  }

  /**
   * draw emergency
   *
   * @param emergencyData
   * @param isText
   * @param isPicture
   */
  private drawEmergency(emergencyData: EmergencyData, isText?: boolean, isPicture?: boolean): void {
    const areasDisplay1 = this.getAllAreasEmergencyMessage(DisplaysEnum.DISPLAY_1, isText, isPicture);
    this.drawTimetableService.clearTimeoutsStopDurationArea(false, ScreenCanvasIdEnum.TIMETABLE_OPERATION_MANAGER, [
      LinkDataTextEnum.EMERGENCY_MESSAGE
    ]);
    this.drawTimetableService.setDataPreviewTimetableOperationManager(null, null, null, emergencyData);
    this.drawTimetableService.clearAreas(areasDisplay1, this.canvasDisplay1Id, ScreenCanvasIdEnum.TIMETABLE_OPERATION_MANAGER);
    // draw area display 1
    let display1 = _.get(this.timetableSelected?.templateDisplay1s, `[${this.templateSelectedTypeDisplay1}]`, undefined);
    this.drawAreasEmergencyForDisplay(display1, areasDisplay1, this.canvasDisplay1Id, emergencyData);
    if (this.isDisplay2) {
      const areasDisplay2 = this.getAllAreasEmergencyMessage(DisplaysEnum.DISPLAY_2, isText, isPicture);
      this.drawTimetableService.clearAreas(areasDisplay2, this.canvasDisplay2Id, ScreenCanvasIdEnum.TIMETABLE_OPERATION_MANAGER);
      // draw area display 2
      let display2 = _.get(this.timetableSelected?.templateDisplay2s, `[${this.templateSelectedTypeDisplay2}]`, undefined);
      this.drawAreasEmergencyForDisplay(display2, areasDisplay2, this.canvasDisplay2Id, emergencyData);
    }
  }

  /**
   * draw areas emergency for display
   * @param display
   * @param areasEmergency
   * @param canvasDisplayId
   * @param emergencyData
   */
  private drawAreasEmergencyForDisplay(
    display: Template,
    areasEmergency: Area[],
    canvasDisplayId: string,
    emergencyData: EmergencyData
  ): void {
    // draw layer off
    let areasEmergencyLayerOff = Helper.getAreasOfLayerOnOff(areasEmergency, display, this.areaSwitchingTiming);
    this.drawTimetableService.drawEmergency(
      emergencyData,
      areasEmergencyLayerOff,
      canvasDisplayId,
      ScreenCanvasIdEnum.TIMETABLE_OPERATION_MANAGER,
      display
    );
    let areasEmergencyLayerOn = Helper.getAreasOfLayerOnOff(areasEmergency, display, this.areaSwitchingTiming, true);
    // draw layer on
    this.drawTimetableService.drawEmergencyOn(
      emergencyData,
      areasEmergencyLayerOn,
      canvasDisplayId,
      ScreenCanvasIdEnum.TIMETABLE_OPERATION_MANAGER,
      display
    );
  }

  /**
   * Check active div
   *
   * @param e
   * @param isCheckActive
   * @returns
   */
  public checkActiveDiv(e: any, isCheckActive: boolean): void {
    if (this.schedules.length == 0 && !isCheckActive) {
      return;
    }
  }

  /**
   * preview finish schedule
   *
   * @param activeScheduleRow
   */
  private previewFinishSchedule(activeScheduleRow: ActiveScheduleRow): void {
    let display1 = _.get(this.timetableSelected?.templateDisplay1s, `[${this.templateSelectedTypeDisplay1}]`, undefined);
    const areasDisplay1 = Helper.getAllAreaTemplate(display1);
    let display2 = _.get(this.timetableSelected?.templateDisplay2s, `[${this.templateSelectedTypeDisplay2}]`, undefined);

    const areasDisplay2 = Helper.getAllAreaTemplate(display2);
    if (!Object.values(activeScheduleRow).every(value => !value)) {
      this.clearFixAreaTimingOn(areasDisplay1, areasDisplay2);
      return;
    }
    // draw area is timing on
    this.drawTimetableService.drawPreviewFixAreaTimingOn(
      areasDisplay1?.filter(area => area.isFix && area.isTimingOn),
      this.renderer,
      this.canvasDisplay1Id,
      ScreenCanvasIdEnum.TIMETABLE_OPERATION_MANAGER,
      true,
      display1
    );
    if (this.isDisplay2) {
      this.drawTimetableService.drawPreviewFixAreaTimingOn(
        areasDisplay2?.filter(area => area.isFix && area.isTimingOn),
        this.renderer,
        this.canvasDisplay2Id,
        ScreenCanvasIdEnum.TIMETABLE_OPERATION_MANAGER,
        true,
        display2
      );
    }
  }

  /**
   * clear Fix Area Timing On
   * @param areasDisplay1
   * @param areasDisplay2
   */
  private clearFixAreaTimingOn(areasDisplay1: Array<Area>, areasDisplay2: Array<Area>): void {
    this.drawTimetableService.clearFixAreaTimingOn(
      areasDisplay1?.filter(area => area.isFix && area.isTimingOn),
      this.canvasDisplay1Id
    );
    if (this.isDisplay2) {
      this.drawTimetableService.clearFixAreaTimingOn(
        areasDisplay2?.filter(area => area.isFix && area.isTimingOn),
        this.canvasDisplay2Id
      );
    }
  }

  /**
   * operation information setting
   */
  private operationInformationSetting(): void {
    // open popup
    this.dialogService.showDialog(DialogOperationInformationSettingComponent, { data: {} }, result => {
      if (result) {
        this.operationInfoOptions = result;
        this.schedules.forEach((schedule, index) => {
          const oldDelayInfo = this.oldOperationInfos.find(
            operationInfo => operationInfo.id == this.scheduleOperationInfo.operationInfo[index]
          );
          const newDelayInfo = this.operationInfoOptions.find(operationInfo => operationInfo.id == schedule.operationInfo?.id);
          schedule.operationInfo = newDelayInfo ?? schedule.operationInfo;
          if (newDelayInfo) {
            schedule.isChangedDelayInfo =
              schedule.operationInfo.time !== oldDelayInfo?.time || schedule.operationInfo.text !== oldDelayInfo?.text;
          }
        });
        this.drawOperationInfos();
      }
    });
  }

  /**
   * Check valid data API for new day
   *
   * @param responseAPI
   * @returns
   */
  private checkValidDataAPIForNewDay(responseAPI: any): boolean {
    return (
      responseAPI[this.AUTOMATION_MODE] == true &&
      !responseAPI[this.EMERGENCY_MESSAGE][this.IMAGE_ATTRIBUTE] &&
      !responseAPI[this.EMERGENCY_MESSAGE][this.MESSAGE_ATTRIBUTE] &&
      [...responseAPI[this.DELAY_INFORMATION]].filter(data => data).length == 0
    );
  }

  /**
   * Get data device every day
   */
  private getDataDeviceEveryDay(): void {
    let currentTime = Helper.convertHoursToSeconds(this.getCurrentDateByTimeZone(true));
    let timeDelay = Constant.SECONDS_OF_ONE_DAY - currentTime + this.timeDateLine;
    if (currentTime < this.timeDateLine) {
      timeDelay = this.timeDateLine - currentTime - 1;
    }
    this.resetTimerGetDataEveryDaySubject
      .pipe(startWith(true), delay(timeDelay * 1000), takeUntil(this.pauseTimerGetDataEveryDaySubject))
      .subscribe(() => {
        this.dialogService.closeAllDialog();
        this.isEditDynamicMessageMode = false;
        this.dataService.sendData([`${MODULE_NAME[FIELD_COMPONENT.TimetableOperationManagerComponent]}:isSelectDynamicMessage`, false]);
        this.isEditEmergencyMode = false;
        this.isViewMonitorMode = false;
        this.dataService.sendData([`${MODULE_NAME[FIELD_COMPONENT.TimetableOperationManagerComponent]}:isViewMonitorMode`, false]);
        this.commonObject.isViewMonitorMode = this.isViewMonitorMode;
        this.store.dispatch(
          new SaveMainStateAction({
            common: this.commonObject
          })
        );
        // Change timetable mode
        const payloadTimetableMode = {
          device: this.deviceSelected.registrationId,
          auto_mode: true
        };
        // off emergency mode for device
        const registrationIds = [this.deviceSelected.registrationId];
        const payloadOffEmergency = {
          id: registrationIds,
          timeoutSec: this.DEFAULT_TIMEOUT
        };
        // reset delay information
        const payloadDelayInfo = {
          device: this.deviceSelected.registrationId,
          operationInfo: []
        };
        forkJoin({
          timetableMode: this.apiCustomerService.changeTimetableMode(payloadTimetableMode),
          emergency: this.deviceService.offEmergencyMode(payloadOffEmergency),
          delayInfo: this.apiCustomerService.changeTimetableDelayInfo(payloadDelayInfo),
          // delete schedule display index
          deleteScheduleDisplayIndex: this.scheduleDisplayIndexService.deleteScheduleDisplayIndex()
        }).subscribe(
          data => {
            const payloadRealTime = {
              device: this.deviceSelected.registrationId
            };
            const intervalCallAPI = interval(this.NUMBER_MILLISECONDS_CALL_API);
            const takeNumbers = intervalCallAPI.pipe(take(this.NUMBERS_OF_API_CALLS));
            this.executingService.executing();
            const sub = takeNumbers.subscribe(
              count => {
                // call api realtime status
                this.apiCustomerService.getTimetableRealtimeStatus(payloadRealTime).subscribe(
                  dataResponse => {
                    if (dataResponse && this.checkValidDataAPIForNewDay(dataResponse)) {
                      this.executingService.executed();
                      sub.unsubscribe();
                      // timetable mode
                      this.deviceSelected.isManuallySetOperatingSystem = false;
                      this.devices.find(
                        device => device.registrationId == this.deviceSelected.registrationId
                      ).isManuallySetOperatingSystem = false;
                      // update emergency
                      if (data.emergency) {
                        this.updateEmergencyMode(registrationIds, false);
                      }
                      // reset emergency data
                      let newEmergencyData = new EmergencyData(Constant.EMPTY, -1);
                      newEmergencyData.id = this.emergencyData.id;
                      this.emergencyMediaIdOld = this.emergencyData.emergencyImageId;
                      // get list dynamic messages of device
                      const dynamicMessagesSave = this.dynamicMessages
                        .filter(dynamicMessage => dynamicMessage.device.id == this.deviceSelected.id)
                        .map(message => {
                          return {
                            id: message.id,
                            textArea: message.textArea,
                            pictureArea: message.pictureArea,
                            deviceId: message.device.id,
                            message: message.message,
                            mediaId: message.media?.id
                          };
                        });
                      forkJoin({
                        //Save emergency
                        saveEmergency: this.emergencyDataService.save(newEmergencyData).toPromise(),
                        // save dynamic message
                        SaveDynamicMessages: this.dynamicMessageService.saveDynamicMessages(dynamicMessagesSave).toPromise()
                      }).subscribe(
                        () => {
                          this.deviceService.updateDataForOneDevice(this.deviceSelected).subscribe(saveDevice => {
                            if (saveDevice) {
                              let index = this.devices.findIndex(device => device.registrationId == saveDevice.registrationId);
                              this.devices[index] = saveDevice;
                              this.selectDevice(saveDevice, index);
                            }
                          });
                        },
                        () => {
                          this.handleErrorFromApi();
                          this.getDataDeviceEveryDay();
                        }
                      );
                      this.pauseTimerGetDataEveryDaySubject.next();
                    } else if (count == this.NUMBERS_OF_API_CALLS - 1) {
                      this.executingService.executed();
                      this.getDataDeviceEveryDay();
                      this.handleErrorFromCustomerApi('call-realtime-failed');
                      return;
                    }
                  },
                  error => {
                    if (count == this.NUMBERS_OF_API_CALLS - 1) {
                      this.handleErrorFromCustomerApi('call-realtime-failed', error);
                      this.getDataDeviceEveryDay();
                      this.executingService.executed();
                    } else {
                      this.executingService.executing();
                    }
                  }
                );
              },
              () => this.executingService.executed()
            );
          },
          () => {
            this.getDataDeviceEveryDay();
            this.handleErrorFromApi();
          }
        );
      });
  }

  /**
   * Get current Date by conditions
   * @returns
   */
  private getCurrentDateByConditions(): string {
    if (Helper.convertHoursToSeconds(this.getCurrentDateByTimeZone(true)) <= this.timeDateLine) {
      const currentDate = new Date(this.getCurrentDateByTimeZone(false));
      currentDate.setDate(currentDate.getDate() - 1);
      return moment(currentDate).format(Constant.FORMAT_DATE);
    }
    return this.getCurrentDateByTimeZone(false);
  }

  /**
   * refresh
   */
  public refresh(): void {
    // mode monitor
    if (this.isViewMonitorMode) {
      this.checkNetWorkAndGetDataPreviewModeMonitor(false, true);
    } else {
      this.checkNetWorkAndGetDataPreviewModeMonitor(true);
    }
  }

  /**
   * check network and get data preview mode monitor
   * @param isSelectDevice
   * @param isReload
   */
  private checkNetWorkAndGetDataPreviewModeMonitor(isSelectDevice?: boolean, isReload?: boolean): void {
    let payload = { id: [this.deviceSelected.registrationId], timeoutSec: this.TIMEOUT_SEC };
    this.executingService.executing();
    this.apiCustomerService.getRealtimeStatusRequest(payload).subscribe(
      responseData => {
        this.executingService.executed();
        this.isNetworkOK = !!_.get(responseData?.['completed'], `[0]`, undefined);
        if (isSelectDevice) {
          return;
        }
        if (this.isNetworkOK) {
          this.executingService.executed();
        }
      },
      () => {
        this.executingService.executed();
        this.isNetworkOK = false;
        if (this.isViewMonitorMode) {
          this.executingService.executed();
        }
      }
    );
  }

  /**
   * get row name
   */
  private getRowName(): void {
    for (let i = 0; i < this.MAX_CURRENT_INDEX; i++) {
      let row = {
        id: i,
        name: i == 0 ? this.translateService.instant('timetable-operation-manager.first') : `+${i}`
      };
      this.rowName.push(row);
    }
  }

  /**
   * Clear emergency image
   */
  public clearEmergencyImage(): void {
    if (this.emergencyData.urlImage == '' || !this.validateActionCommon()) {
      return;
    }
    this.dialogService.showDialog(
      DialogConfirmComponent,
      {
        data: {
          text: this.translateService.instant('dialog-confirm.timetable-operation-manager.want-to-clear-image'),
          button1: this.translateService.instant('dialog-confirm.timetable-operation-manager.button-1'),
          button2: this.translateService.instant('dialog-confirm.timetable-operation-manager.button-2')
        }
      },
      result => {
        if (result) {
          this.emergencyData.media = undefined;
          this.emergencyData.urlImage = '';
          this.emergencyData.emergencyImageId = -1;
          this.drawTimetableService.setDataPreviewTimetableOperationManager(null, null, null, this.emergencyData);
          setTimeout(() => {
            const pictureAreas1 = this.getAllAreasEmergencyMessage(DisplaysEnum.DISPLAY_1, false, true);
            this.drawTimetableService.clearAreas(pictureAreas1, this.canvasDisplay1Id, ScreenCanvasIdEnum.TIMETABLE_OPERATION_MANAGER);
            if (this.isDisplay2) {
              const pictureAreas2 = this.getAllAreasEmergencyMessage(DisplaysEnum.DISPLAY_2, false, true);
              this.drawTimetableService.clearAreas(pictureAreas2, this.canvasDisplay2Id, ScreenCanvasIdEnum.TIMETABLE_OPERATION_MANAGER);
            }
          });
        }
      }
    );
  }

  /**
   * Drop media from pc
   *
   * @param file
   * @param dynamicMessage
   * @param indexDynamic
   */
  public async dropMediaFromPCDynamicMessage(file: any, dynamicMessage: DynamicMessage, indexDynamic: number): Promise<void> {
    if (dynamicMessage.textArea || !this.validateActionCommon()) {
      return;
    }
    if (!Helper.isImageFile(file[Constant.FILE_MEDIA_OBJECT])) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-message.error-title'),
          text: this.translateService.instant('dialog-message.timetable-operation.invalid-file')
        }
      });
      return;
    }
    // handle file drop
    let fileDrop = file[0][0];
    const mediaType = file[Constant.FILE_MEDIA_OBJECT][Constant.TYPE_ATTRIBUTE];
    const fileName = `${this.IMAGE_NAME_DROP_MEDIA}_${moment(new Date()).format(Constant.FORMAT_DATE_DROP_MEDIA)}.${mediaType}`;
    // file is pdf
    if (mediaType.toLowerCase() == TypeMediaFileEnum.PDF) {
      const numberOfPage = await this.mediaService
        .getNumberOfPagePdf(new File([fileDrop], fileDrop[Constant.NAME_ATTRIBUTE]))
        .toPromise()
        .catch(error => {
          Helper.handleError(error, this.translateService, this.dialogService);
          return 0;
        });
      if (numberOfPage > 1) {
        this.dialogService.showDialog(
          DialogConfirmComponent,
          {
            data: {
              text: this.translateService.instant('dialog-confirm.timetable-operation-manager.confirm-convert-file-pdf'),
              button1: this.translateService.instant('dialog-confirm.timetable-operation-manager.button-1'),
              button2: this.translateService.instant('dialog-confirm.timetable-operation-manager.button-2')
            }
          },
          result => {
            if (!result) {
              return;
            }
            this.handleAfterDropMediaToDynamicMessages(mediaType, fileDrop, fileName, dynamicMessage, file, indexDynamic);
          }
        );
      } else if (numberOfPage == 1) {
        this.handleAfterDropMediaToDynamicMessages(mediaType, fileDrop, fileName, dynamicMessage, file, indexDynamic);
      }
    } else {
      this.handleAfterDropMediaToDynamicMessages(mediaType, fileDrop, fileName, dynamicMessage, file, indexDynamic);
    }
  }
  /**
   * handle After Drop Media To DynamicMessages
   * @param mediaType
   * @param fileDrop
   * @param fileName
   * @param dynamicMessage
   * @param file
   * @param indexDynamic
   * @returns
   */
  private async handleAfterDropMediaToDynamicMessages(
    mediaType: any,
    fileDrop: any,
    fileName: any,
    dynamicMessage: DynamicMessage,
    file: any,
    indexDynamic: number
  ): Promise<void> {
    let fileFromPC: Media = null;
    // file is pdf
    if (mediaType.toLowerCase() == TypeMediaFileEnum.PDF) {
      let isErrorFonts = await this.mediaService
        .checkFontsConvert(new File([fileDrop], fileName))
        .toPromise()
        .catch(error => {
          this.dialogService.showDialog(DialogMessageComponent, {
            data: {
              title: this.translateService.instant('index-word-editor.msg.title-error'),
              text: this.translateService.instant('lcd-layout-editor.msg.common-error')
            }
          });
          return null;
        });
      if (isErrorFonts) {
        this.dialogService.showDialog(
          DialogConfirmComponent,
          {
            data: {
              text: this.translateService.instant('lcd-layout-editor.msg.fonts-error-convert-pdf'),
              button1: this.translateService.instant('lcd-layout-editor.yes'),
              button2: this.translateService.instant('lcd-layout-editor.no')
            },
            autoFocus: false
          },
          async result => {
            if (!result) {
              return;
            }
            fileFromPC = await this.mediaService
              .convertPDFToImage(new File([fileDrop], fileName), FolderNameDropPDFEnum.DYNAMIC_MESSAGE)
              .toPromise()
              .catch(error => {
                this.dialogService.showDialog(DialogMessageComponent, {
                  data: {
                    title: this.translateService.instant('dialog-error.title'),
                    text: this.translateService.instant('timetable-operation-manager.pdf-cannot-display')
                  }
                });
                return null;
              });

            if (fileFromPC == null) {
              return;
            }
            this.showImgDynamicMessage(dynamicMessage, fileDrop, fileName, fileFromPC, mediaType, file, indexDynamic);
          }
        );
      } else {
        fileFromPC = await this.mediaService
          .convertPDFToImage(new File([fileDrop], fileName), FolderNameDropPDFEnum.DYNAMIC_MESSAGE)
          .toPromise()
          .catch(error => {
            this.dialogService.showDialog(DialogMessageComponent, {
              data: {
                title: this.translateService.instant('dialog-error.title'),
                text: this.translateService.instant('timetable-operation-manager.pdf-cannot-display')
              }
            });
            return null;
          });

        if (fileFromPC == null) {
          return;
        }
        this.showImgDynamicMessage(dynamicMessage, fileDrop, fileName, fileFromPC, mediaType, file, indexDynamic);
      }
    } else {
      this.showImgDynamicMessage(dynamicMessage, fileDrop, fileName, fileFromPC, mediaType, file, indexDynamic, true);
    }
  }

  /**
   * showImgDynamicMessage
   * @param dynamicMessage
   * @param fileDrop
   * @param fileName
   * @param fileFromPC
   * @param mediaType
   * @param file
   * @param indexDynamic
   */
  private async showImgDynamicMessage(dynamicMessage, fileDrop, fileName, fileFromPC, mediaType, file, indexDynamic, isCheck?) {
    let imageInfo = await this.getImageInformation(fileDrop);
    // validate unsupported file format
    if (isCheck && imageInfo[Constant.ERROR_ELEMENT]) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-message.error-title'),
          text: this.translateService.instant('dialog-error.unsupported-file-format')
        }
      });
      return;
    }
    if (dynamicMessage.media && !dynamicMessage.uuidV4Image) {
      this.mediaIdsToDelete.push(dynamicMessage.media.id);
    }
    let uuid = uniqueId();
    const fileRenamed = new File([fileDrop], uuid);
    let mediaFile = new MediaFileDropped(uuid, fileName);
    const index = this.mediaFilesDroppedOperation?.findIndex(data => data?.uuidV4 == dynamicMessage.uuidV4Image);
    if (index == -1) {
      this.mediaFilesDroppedOperation.push(mediaFile);
      this.filesData.push(fileRenamed);
    } else {
      this.mediaFilesDroppedOperation[index] = mediaFile;
      this.filesData[index] = fileRenamed;
    }
    dynamicMessage.uuidV4Image = uuid;
    let mediaFromPC: Media = new ImageDynamicMessage();
    mediaFromPC.url = fileFromPC ? fileFromPC.url : file[Constant.FILE_MEDIA_OBJECT][Constant.URL_ATTRIBUTE];
    mediaFromPC.type = mediaType;
    dynamicMessage.media = mediaFromPC;
    dynamicMessage.isChanged = this.oldDynamicMessages[indexDynamic].media != dynamicMessage.media;
    this.drawDynamicMessages(this.dynamicMessages.filter(dynamic => dynamic.device.id == this.deviceSelected.id));
  }
  /**
   * Handle show hide column text and media
   */
  private handleShowColumnTextAndMedia(): void {
    this.isOnlyTextArea = this.dynamicMessages?.every(dynamicMessage => dynamicMessage.textArea != null);
    this.isOnlyPictureArea = this.dynamicMessages?.every(dynamicMessage => dynamicMessage.pictureArea != null);
  }

  /**
   * clear data media drop to dynamic message
   */
  private clearDataMediaDropToDynamicMessages(): void {
    this.mediaFilesDroppedOperation = new Array();
    this.filesData = [];
    this.mediaIdsToDelete = new Array();
    this.dynamicMessages.forEach(dynamicMessage => (dynamicMessage.uuidV4Image = ''));
  }

  /**
   * Capture screen device
   */
  public captureScreenDevice(): void {
    if (!this.deviceSelected) {
      this.handleShowMessage(Constant.ERROR_TITLE, 'no-device');
    }
    // call API status to check network controller device
    this.apiCustomerService.getCurrentStatusRequest([this.deviceSelected.registrationId]).subscribe(data => {
      this.isNetworkOK = !!_.get(data?.['completed'], `[0]`, undefined);
      // update information for device selected
      let deviceCompleted = data[Constant.COMPLETE_STATUS]?.find(item => this.deviceSelected.registrationId === item.id);
      this.dataService.sendData([Constant.IS_NETWORK_OK, !!deviceCompleted]);
      // check network OK/NG
      if (!deviceCompleted) {
        return;
      }
      // network OK => call API screen capture
      this.handleCallAPIScreenCapture();
    });
  }

  /**
   * handle call api screen capture
   */
  private handleCallAPIScreenCapture() {
    const payload = {
      device: this.deviceSelected.registrationId
    };
    this.apiCustomerService.captureScreenDevice(payload).subscribe(
      () => {
        this.executingService.executing();
        setTimeout(() => {
          this.deviceService.getDeviceScreenCaptureUrl(this.deviceSelected.registrationId).subscribe(
            data => {
              this.executingService.executed();
              if (!data) {
                return;
              }
              this.timeByImg = data['time'];
              this.lastTimeUpdate = Helper.formatString(
                this.translateService.instant(`timetable-operation-manager.last-time-update`),
                Helper.updateLanguageLastTime(this.timeByImg, this.languageKey)
              );
              this.deviceSelected.captureUrl = data['url'];
            },
            () => {
              this.executingService.executed();
              this.handleShowMessage(Constant.ERROR_TITLE, Constant.COMMON_ERROR);
            }
          );
        }, 3000);
      },
      () => this.handleShowMessage(Constant.ERROR_TITLE, 'call-screen-failed')
    );
  }

  /**
   * Handle show message
   *
   * @param title
   * @param messageCode
   */
  private handleShowMessage(title: string, messageCode: string): void {
    this.dialogService.showDialog(DialogMessageComponent, {
      data: {
        title: this.translateService.instant(`schedule-operation-manager.message.${title}`),
        text: this.translateService.instant(`schedule-operation-manager.message.${messageCode}`)
      }
    });
  }

  /**
   * clearField
   */
  private clearField(): void {
    this.dynamicMessageSelected.media = undefined;
    this.dynamicMessageSelected.message = undefined;
    this.dynamicMessageSelected.isChanged = true;
    if (!this.isViewMonitorMode) {
      this.drawDynamicMessages(this.dynamicMessages.filter(dynamic => dynamic.device.id == this.deviceSelected.id));
    }
  }
  /**
   * selectDynamicMessage
   * @param dynamicMessage
   */
  public selectDynamicMessage(dynamicMessage: DynamicMessage) {
    this.dynamicMessageSelected = dynamicMessage;
    this.dataService.sendData([`${MODULE_NAME[FIELD_COMPONENT.TimetableOperationManagerComponent]}:isSelectDynamicMessage`, true]);
  }

  /**
   * send Timezone To Backend
   */
  private sendTimezoneToBack(): void {
    let timeZone = this.commonService
      .getCommonObject()
      .setting.timezone.name.split(' ')[0]
      .replace(')', '')
      .replace('(', '');
    this.timetableService.transferTimezoneToBack(timeZone).subscribe();
  }
}

/**
 * Class schedule
 */
export class Schedule {
  list: any[];
  convertedTime: string;
  operationInfo: OperationInformationSetting;
  isLessCurrentTime: boolean = false;
  rowName: string;
  isChangedDelayInfo: boolean = false;
  isChangedIndex: boolean = false;
  constructor() {
    this.list = [];
  }
}

/**
 * Class activeScheduleRow
 */
export class ActiveScheduleRow {
  current_0: Schedule;
  current_1: Schedule;
  current_2: Schedule;
  current_3: Schedule;
  current_4: Schedule;
  constructor() {}
}

/**
 * API Enum
 */
export enum APIEnum {
  OFF_EMERGENCY = 0,
  DYNAMIC_MESSAGE = 1,
  ON_EMERGENCY = 2
}
